import { VoidFunctionComponent, useCallback } from 'react';
import moment from 'moment';
import {
  View,
  Button,
  SmallCalendar,
  Standard,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { VideoCreditOffer } from 'ts-frontend/types';
import { getIsIonic } from 'ts-ionic';
import styled from '@/core/styled';
import appConfigs from '@/utils/configs';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';

const { deepLinks } = appConfigs;

const reactFrameService = ReactFrameService.instance();

const IconWrapper = styled(View)({ width: 24 });

const CalendarButton = styled(Button)(({ theme: { colorRoles, colors }, style }) => {
  return {
    flex: 1,
    marginRight: 8,
    backgroundColor: colorRoles.system.actionSecondaryDefault,
    color: colorRoles.system.actionSecondaryText,
    border: `1px solid ${colors.green}`,
    ...style,
  };
});

interface SelectedBooking {
  startTime: string;
  creditMinutes: number;
}

interface AddToCalendarParams {
  bookingID?: string;
  selectedBooking?: SelectedBooking;
  videoCreditType?: VideoCreditOffer['type'];
  therapistName?: string;
  roomID?: number;
}

const getEventDates = (selectedBooking?: SelectedBooking) => {
  const startDate = moment(selectedBooking?.startTime);
  const endDate = moment(selectedBooking?.startTime).add(selectedBooking?.creditMinutes, 'm');
  return { startDate, endDate };
};

const AddToCalendar: VoidFunctionComponent<AddToCalendarParams> = ({
  bookingID,
  selectedBooking,
  videoCreditType,
  therapistName,
  roomID,
}) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const therapist = therapistName;
  const title = `Talkspace ${videoCreditType || 'live'} session`;
  const link = deepLinks
    ? `${deepLinks.universalDeepLinkDomain}/room/${roomID}`
    : `https://app.talkspace.com/room/${roomID}`;
  const body = `To join your therapy session with ${therapist}, click <a href=${link}>here</a>.<br /><br />Helpful notes:<br /><br />- Please make sure you are in a quiet place with good internet connection.<br />- Please enable your mic and camera.<br /><br />If you need to cancel or reschedule your session, click here. Please note cancellations within 24 hours of session start time or no-shows will result in a fee or lost credit.`;

  const closePopup = useCallback((url: string) => {
    const closingPayload: ClosePopupAction = {
      navigateTo: 'browser',
      metadata: { path: url },
    };
    reactFrameService.closePopup(closingPayload);
  }, []);

  const handleGooglePress = useCallback(() => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    // ISO 8601 Internet Date/Time Format example 20170127T210000Z/20170127T220000Z
    const formatter = 'YYYYMMDDTHHmmss'; // add the "Z" in the final string
    const startFormatted = startDate.utc().format(formatter);
    const endFormatted = endDate.utc().format(formatter);

    const dates = `${startFormatted}Z/${endFormatted}Z`;
    const urlParams = new URLSearchParams({
      dates,
      text: title,
      details: body,
      location: link,
    });

    const url = `https://calendar.google.com/calendar/u/0/r/eventedit?${urlParams.toString()}`;

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.open(url);
    }
  }, [link, title, selectedBooking, body, closePopup]);

  const handleApplePress = useCallback(async () => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    const description = `To join your therapy session with ${therapist}, click url in this event.  Here's some helpful notes.  Please make sure you are in a quiet place with good internet connection.  Please enable your mic and camera.  If you need to cancel or reschedule your session, you can. Please note cancellations within 24 hours of session start time or no-shows will result in a fee or lost credit.`;
    if (getIsIonic()) {
      const { CapacitorCalendar } = await import('@ebarooni/capacitor-calendar');
      await CapacitorCalendar.createEventWithPrompt({
        title,
        location: link,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf(),
        isAllDay: false,
        url: link,
        notes: description,
      });
      return;
    }
    const url = encodeURI(
      `data:text/calendar;charset=utf8;filename=hi.ics,${[
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'PRODID:www.talkspace.com',
        'BEGIN:VEVENT',
        'CLASS:PUBLIC',
        `DTSTART;VALUE=DATE-TIME:${startDate.format('YYYYMMDDTHHmmss')}`,
        `DTEND;VALUE=DATE-TIME:${endDate.format('YYYYMMDDTHHmmss')}`,
        `SUMMARY:${title}`,
        'TRANSP:TRANSPARENT',
        `DESCRIPTION:${description}`,
        `LOCATION:${link}`,
        `UID:${bookingID || startDate.format('YYYYMMDDTHHmmss').toString()}`,
        `DTSTAMP;VALUE=DATE-TIME:${startDate.format('YYYYMMDDTHHmmss')}`,
        'END:VEVENT',
        'END:VCALENDAR',
        `URL:${link}`,
      ].join('\n')}`
    );

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.location.href = url;
    }
  }, [bookingID, link, title, selectedBooking, therapist, closePopup]);

  const handleOutlookPress = useCallback(() => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    const url = `https://outlook.live.com/calendar/0/action/compose?subject=${title}&startdt=${startDate.format(
      'YYYY-MM-DDTHH:mm:ss'
    )}&enddt=${endDate.format(
      'YYYY-MM-DDTHH:mm:ss'
    )}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&body=${body}`;

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.open(url);
    }
  }, [title, selectedBooking, body, closePopup]);

  if (!selectedBooking) {
    return null;
  }

  return (
    <View align="start" justify="space-between" style={{ width: isMobile ? 335 : 430 }}>
      <View align="start">
        <View row tabIndex={-1}>
          <IconWrapper>
            <SmallCalendar color={colors.black} />
          </IconWrapper>
          <Standard style={{ marginLeft: 15, marginTop: 1 }}>Add to calendar</Standard>
        </View>
      </View>
      <View row justify="space-between" style={{ width: '100%', marginTop: 16 }}>
        <CalendarButton text="Google" onPress={handleGooglePress} dataQa="addToGoogleCalendar" />
        <CalendarButton text="Apple" onPress={handleApplePress} dataQa="addToAppleCalendar" />
        <CalendarButton
          text="Outlook"
          onPress={handleOutlookPress}
          dataQa="addToOutlookCalendar"
          style={{ marginRight: 0 }}
        />
      </View>
    </View>
  );
};

export default AddToCalendar;
