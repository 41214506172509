import { Button, useWindowWidthState } from '@talkspace/react-toolkit';
import { getBookSessionCTAText } from 'ts-frontend/utils/displayStrings';
import { getIsMessagingEnabled } from 'ts-frontend/helpers/subscription';
import { Subscription, Booking } from 'ts-frontend/types';
import { useOpenModal } from '@/utils/ModalsContextProvider';

import { AggregatedRoomData } from '../../hooks/useNavShellData';
import useJoinSession from '../../hooks/useJoinSession';
import { trackCTAClick } from '../../utils/analytics/events';

const YourSupportTeamCardCTA = ({
  subscription,
  activeSession,
  bookings,
  roomID,
  nextBooking,
}: {
  subscription: Subscription;
  activeSession: AggregatedRoomData['activeSession'];
  bookings: AggregatedRoomData['bookings'];
  roomID: number;
  nextBooking?: Booking;
}) => {
  const { isMobile } = useWindowWidthState();
  const { videoCallID, booking: sessionBooking } = activeSession || { therapist: {} };
  const bookingForActiveSession =
    activeSession &&
    bookings?.find(
      (b) =>
        b.videoCallID === videoCallID ||
        (sessionBooking?.startTime === b.startTime &&
          sessionBooking.creditMinutes === b.creditMinutes &&
          sessionBooking.type === b.type)
    );
  const { isB2C, isBH } = subscription.subscription;
  const isDTEOrB2CAccount = subscription.planDetail?.accountType === 'dte' || isB2C;
  const hasNoVideoCredits = subscription.videoCredits?.length === 0;

  const { onClickJoinSession, isDisabled, isTentative } = useJoinSession({
    roomID,
    activeSession,
    booking: activeSession ? bookingForActiveSession : nextBooking,
    useRedirect: false,
    isBH,
  });
  const openModal = useOpenModal();
  if (activeSession) {
    return (
      <Button
        stretch={isMobile}
        onPress={(event) => {
          trackCTAClick('Join session', 'home', { roomID });
          onClickJoinSession(event);
        }}
        text={isTentative ? 'Continue to session' : 'Join session'}
        disabled={isDisabled}
      />
    );
  }

  const shouldShowBookSessionButton =
    !isDTEOrB2CAccount || (isDTEOrB2CAccount && !hasNoVideoCredits);
  if (!bookings || (bookings.length === 0 && shouldShowBookSessionButton)) {
    const createBookingPath = `/in-room-scheduling/room/${roomID}`;
    return (
      <Button
        stretch={isMobile}
        dataQa={`yourSupportTeamCardCreateBooking-${roomID}`}
        onPress={(event) => {
          trackCTAClick('Book a session', 'home', { roomID, component: 'YourSupportTeamCard' });
          openModal(createBookingPath);
          event.preventDefault();
          event.stopPropagation();
        }}
        text={getBookSessionCTAText({
          isMessagingEnabled: getIsMessagingEnabled({ subscription }),
        })}
      />
    );
  }

  const tentativeBooking = bookings?.find(
    (booking) =>
      booking.timekitBookingState === 'tentative' &&
      booking.scheduledByUserType === 'provider' &&
      booking.status === 'active'
  );
  if (tentativeBooking && tentativeBooking.id === nextBooking?.id) {
    const confirmBookingPath = `/in-room-scheduling/room/${roomID}/confirm-booking/booking/${tentativeBooking.id}?batch=true`;
    return (
      <Button
        stretch={isMobile}
        onPress={(event) => {
          trackCTAClick('Confirm session', 'home', { roomID });
          openModal(confirmBookingPath);
          event.preventDefault();
          event.stopPropagation();
        }}
        text="Confirm session"
      />
    );
  }
  return null;
};

export default YourSupportTeamCardCTA;
