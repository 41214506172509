import { FunctionComponent } from 'react';
import {
  View,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { isPendingSubscriptionRoom } from 'ts-frontend/helpers';
import { useNewMemberNav } from 'launchDarkly/hooks';
import PanelTherapistBlock from '../components/PanelTherapistBlock';
import PanelLVSBlock from '../components/PanelLVSBlock';
import PanelRoomDetailsTools from '../components/PanelRoomDetailsTools';
import { RouteComponentProps, withRouter } from '../core/routerLib';
import { useMainState } from '../hooks/mainContext';
import { extractRoomEntitiesFromState } from '../utils/extractRoomEntitiesFromState';
import PanelMyRoomActions from '../components/PanelMyRoomActions';
import { useA11y } from './RoomDetails.a11y';
import { ID_ROOM_DETAILS_PANEL } from '../utils/IDConstants';
import EligibilityWarningBanner from '../EligibilityWarning/components/EligibilityWarningBanner';
import useEligibilityWarning from '../EligibilityWarning/hooks/useEligibilityWarning';
import { useCloseModal } from '@/utils/ModalsContextProvider';

type Props = RouteComponentProps<{ roomID: string }> & {
  isChatHidden?: boolean;
  isMobile?: boolean;
  isModal?: boolean;
};

const RoomDetailsContainer: FunctionComponent<Props> = ({
  match,
  history,
  isChatHidden,
  isMobile,
  isModal,
}) => {
  const roomID = Number(match.params.roomID);
  const { therapist, room } = extractRoomEntitiesFromState(useMainState(), roomID);
  const { isInsuranceIneligible, isClosedUpdatedCoverage } = useEligibilityWarning({ roomID });

  const closeModal = useCloseModal();

  const useNewNav = useNewMemberNav();

  const handleOnRoomPanelClosePress = () => {
    if (isModal) closeModal();
    else history.push(`/room/${match.params.roomID}`);
  };

  const isNoMatchesState = isPendingSubscriptionRoom({
    room,
    provider: therapist,
  });

  const { skipNavs } = useA11y(history);
  const titleId = useUniqueID('titleId');
  return (
    <ResponsiveLayoutWithHeader
      titleId={titleId}
      roomID={Number(match.params.roomID)}
      panelId={ID_ROOM_DETAILS_PANEL}
      renderHeader={() => (
        <PanelHeader
          title="Room details"
          titleId={titleId}
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isChatHidden={isChatHidden}
          isMobile={isMobile}
          useNewNavHeader={useNewNav}
        />
      )}
    >
      <View
        align="center"
        style={{
          paddingBottom: 20,
        }}
      >
        {isInsuranceIneligible && isChatHidden && (
          <EligibilityWarningBanner
            roomID={roomID}
            isClosedUpdatedCoverage={isClosedUpdatedCoverage}
          />
        )}
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            width: 374,
          }}
        >
          <View style={{ marginTop: 33, marginBottom: 58 }}>
            <PanelTherapistBlock
              room={room}
              therapist={therapist}
              avatarSize={85}
              startDate
              clickable={!isNoMatchesState}
              isNoMatchesState={isNoMatchesState}
            />
          </View>
          <PanelLVSBlock isNoMatchesState={isNoMatchesState} />
          <PanelRoomDetailsTools />
          {!isNoMatchesState && (
            <>
              <PanelMyRoomActions />
            </>
          )}
        </View>
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(RoomDetailsContainer);
