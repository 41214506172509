import { forwardRef, useState } from 'react';

import { Input, useEmotionTheme, View, spacing } from '@talkspace/react-toolkit';
import { useToastState } from '@talkspace/react-toolkit/src/hooks/ToastProvider';
import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';

import { createComment } from '../utils/amity';
import { COMMENT_MAX_LENGTH, COMMUNITY_TOASTS } from '../constants';

const { space200 } = spacing;

interface Props {
  postID: string;
}
const TextInput = forwardRef<HTMLInputElement, Props>(({ postID }, ref) => {
  const [text, setText] = useState<string>();
  const { colorRoles } = useEmotionTheme();

  const { showToast } = useToastState();
  const [disabled, setDisabled] = useState<boolean>();

  const { outsideFooterHeight, sidebarWidth } = useNavShellWindowHeight();
  return (
    <View
      style={{
        border: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
        borderRadius: '16px 16px 0 0',
        width: `calc(100% - ${sidebarWidth}px)`,
        padding: space200,
        position: 'fixed',
        bottom: outsideFooterHeight,
        left: sidebarWidth,
        backgroundColor: colorRoles.surfaces.defaultSubtleDefault,
      }}
    >
      <Input
        ref={ref}
        dataQa="commentInput"
        placeholder="Write something..."
        value={text}
        maxLength={COMMENT_MAX_LENGTH}
        onChange={(event) => setText(event.target.value)}
        onSubmitEditing={() => {
          setDisabled(true);
          if (text) {
            createComment({ text, postID })
              .then(() => {
                setText('');
              })
              .catch(() => {
                showToast(COMMUNITY_TOASTS.GENERIC_ERROR);
              })
              .finally(() => {
                setDisabled(false);
              });
          }
        }}
        style={{ borderWidth: 0 }}
        disabled={disabled}
      />
    </View>
  );
});

export default TextInput;
