import {
  EmotionTheme,
  useEmotionTheme,
  View,
  BaseButton,
  Input,
  TextDS,
  spacing,
} from '@talkspace/react-toolkit';
import { useFormContext } from 'react-hook-form';
import { generateUniqueName } from '../utils/helpers';
import { DISPLAY_NAME_MAX_LENGTH_LIMIT } from '../constants';

const { space400 } = spacing;
const displayNameFieldName = 'displayName';

interface Props {
  resetMutation: () => void;
}

const baseInputStyle = (colorRoles: EmotionTheme['colorRoles'], isError: boolean) => {
  return {
    inputStyle: {
      borderColor: isError
        ? colorRoles.typography.textCriticalDefault
        : colorRoles.borders.borderDefault,
      color: 'black',
      '::placeholder': { color: colorRoles.typography.textDefault },
    },
  };
};

const DisplayNameInputField = ({ resetMutation }: Props) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext();
  const { colorRoles } = useEmotionTheme();
  const displayName = watch(displayNameFieldName);
  const displayNameField = register(displayNameFieldName);
  return (
    <View style={{ marginTop: space400, position: 'relative' }}>
      <Input
        maxLength={DISPLAY_NAME_MAX_LENGTH_LIMIT}
        style={{ width: '100%' }}
        dataQa="displayNameInput"
        wrappedInputProps={{
          ...baseInputStyle(colorRoles, !!errors.displayName),
          label: 'Display name',
          isError: !!errors.displayName,
          errorMessage: errors.displayName?.message,
        }}
        {...register(displayNameFieldName)}
        onChange={(e) => {
          displayNameField.onChange(e);
          resetMutation();
          clearErrors();
        }}
      />
      <View style={{ position: 'absolute', left: 259, top: 45 }}>
        {displayName && (
          <BaseButton
            onPress={(e) => {
              e.preventDefault();
              setValue(displayNameFieldName, generateUniqueName());
              clearErrors();
            }}
            dataQa="teenspaceProfileDisplayNameRefreshButton"
          >
            <TextDS variant="headingMd" colorRole="textBrandDefault">
              Refresh
            </TextDS>
          </BaseButton>
        )}
      </View>
    </View>
  );
};

export default DisplayNameInputField;
