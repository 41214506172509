import { ErrorScreen } from '@talkspace/react-toolkit/src/designSystems/organisms';
import { ClockPersonWaiting } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useIsModal } from '../../utils/ModalsContextProvider';

interface Props {
  onPressClose: () => void;
}
const ScheduleChooseProviderWaitForMatch = ({ onPressClose }: Props) => {
  const isModal = useIsModal();
  return (
    <ErrorScreen
      headerTitle="Waiting to be matched"
      illustration={<ClockPersonWaiting />}
      title="Waiting to be matched"
      description="You'll be able to book and view sessions here once you're matched with a provider"
      hasButton={false}
      buttonText=""
      onClose={onPressClose}
      onPressButton={() => {}}
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
    />
  );
};
export default ScheduleChooseProviderWaitForMatch;
