import { useCallback } from 'react';
import {
  Col,
  Grid,
  Row,
  TextDS,
  spacing,
  useWindowWidthState,
  ResponsiveLayoutWithHeader,
  SimpleHeader,
} from '@talkspace/react-toolkit';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import { useHistory } from '@/core/routerLib/routerLib';

import ClinicalProgressRoomContainer from '../../clinicalProgress/containers/ClinicalProgressRoomContainer';
import { trackInAppReview } from '../../utils/analytics/events';

const { space200, space400 } = spacing;

const JourneyScreen = () => {
  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);
  return isMobile ? (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <SimpleHeader
          title="Choose provider"
          onClose={() => {
            history.push('/home');
          }}
        />
      )}
    >
      <ClinicalProgressRoomContainer isV2 />
    </ResponsiveLayoutWithHeader>
  ) : (
    <Grid fluid rowSpacing="space200">
      <Row>
        <Col>
          <TextDS
            as="span"
            variant="heading2xl"
            style={{ marginTop: isMobile ? space200 : space400 }}
          >
            Journey
          </TextDS>
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={8} md={8} lg={12}>
          <ClinicalProgressRoomContainer isV2 />
        </Col>
      </Row>
    </Grid>
  );
};

export default JourneyScreen;
