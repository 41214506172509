import { Large, TextDS, View, spacing } from '@talkspace/react-toolkit';
import { Pen } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled, { EmotionStyle } from '@/core/styled';

import PersonalInformationDataRow from '../PersonalInformationDataRow/PersonalInformationDataRow';
import PersonalInformationDataSection from '../PersonalInformationDataSection';

const { space100, space400, space500 } = spacing;

interface Props {
  displayName: string;
  onEdit: () => void;
  wrapperStyle?: EmotionStyle;
}
const EditButtonContainer = styled(View)({
  position: 'absolute',
  right: space400,
  display: 'inline-flex',
  cursor: 'pointer',
  justifyContent: 'none',
});

const PersonalInformationDataSectionTeenspaceCommunity = ({
  displayName,
  onEdit,
  wrapperStyle = {},
}: Props) => {
  const useNewNav = useNewMemberNav();

  return useNewNav ? (
    <View style={wrapperStyle}>
      <View style={{ marginTop: space500 }}>
        <TextDS variant="headingLg">Teenspace community</TextDS>
      </View>

      <PersonalInformationDataSection
        title=""
        editLinkText=""
        onEditClick={onEdit}
        wrapperStyle={wrapperStyle}
      >
        <EditButtonContainer row>
          <Pen size="major" colorType="brand" />
          <Large
            onPress={onEdit}
            variant="largeBoldTSGreen"
            dataQa="personalInformationEditTeenspaceCommunityButton"
          >
            Edit
          </Large>
        </EditButtonContainer>
        <View style={{ marginBottom: space100 }}>
          <PersonalInformationDataRow title="Display name" value={displayName} />
        </View>
      </PersonalInformationDataSection>
    </View>
  ) : (
    <PersonalInformationDataSection
      title="Teenspace community"
      editLinkText="Change Teenspace community"
      onEditClick={onEdit}
      wrapperStyle={wrapperStyle}
    >
      <PersonalInformationDataRow title="Name" value={displayName} />
    </PersonalInformationDataSection>
  );
};

export default PersonalInformationDataSectionTeenspaceCommunity;
