import { EmotionStyle, TextDS, View } from '@talkspace/react-toolkit';

const EXPO_ONE = -50;
const EXPO_TWO = -40;
const EXPO_THREE = -30;
const EXPO_FOUR = -20;
const EXPO_FIVE = -10;
const EXPO_DEFAULT = 0;

const expoConfig = [
  EXPO_ONE,
  EXPO_ONE,
  EXPO_TWO,
  EXPO_THREE,
  EXPO_FOUR,
  EXPO_FOUR,
  EXPO_FIVE,
  EXPO_FIVE,
];

/**
 * Adds a specific font weight to a word depending on the it's position in a sentence
 * @param sentence sentence to be updated
 * @returns JSX.Element
 */
const WeightedSentence = ({
  sentence,
  style,
}: {
  sentence: string;
  style?: EmotionStyle;
}): JSX.Element => {
  const formattedSentence = sentence.split(' ');
  return (
    <View
      style={{
        display: 'inline',
        textAlign: 'center',
        maxWidth: 460,
        ...style,
      }}
    >
      {formattedSentence.map((word, index) => {
        const expoValue = expoConfig[index] ?? EXPO_DEFAULT;
        return (
          <TextDS
            inline
            // eslint-disable-next-line react/no-array-index-key
            key={`word-${index}`}
            variant="decorative/headingXl"
            style={{ fontVariationSettings: `'EXPO' ${expoValue}` }}
          >
            {word}{' '}
          </TextDS>
        );
      })}
    </View>
  );
};

export default WeightedSentence;
