import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

import Toast from './Toast';

const versionMap: VersionMap<React.ComponentProps<typeof Toast>> = {
  DefaultComponent: Toast,
};

export default withVersioning(versionMap) as typeof Toast;
