import { useEffect } from 'react';

import { View, Spinner, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import { useMainState, useMainActions } from '../../hooks/mainContext';
import ClinicalProgressRoomList from '../components/ClinicalProgressRoomList';
import { getUserData } from '../../auth/helpers/token';
import { useHistory } from '@/core/routerLib';

const { space400 } = spacing;

const ClinicalProgressRoomContainer = ({ isV2 }: { isV2?: boolean }) => {
  const history = useHistory();
  const { roomsByID, therapistsByID } = useMainState();
  const { getAllRooms } = useMainActions();
  const { id: clientUserID } = getUserData();
  const missingData =
    Object.keys(roomsByID).length === 0 || Object.keys(therapistsByID).length === 0;
  const hasOneRoom = Object.keys(roomsByID).length === 1;
  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    if (hasOneRoom) {
      history.push(`/room/${Object.keys(roomsByID)[0]}/clinical-progress`);
    }
  }, [hasOneRoom, history, roomsByID]);

  useEffect(() => {
    if (missingData) {
      getAllRooms(clientUserID);
    }
  }, [clientUserID, missingData, getAllRooms]);

  if (hasOneRoom || missingData) {
    return (
      <View align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner />
      </View>
    );
  }

  return (
    <View justify="center" align="center" style={{ paddingTop: isMobile && isV2 ? 0 : space400 }}>
      <ClinicalProgressRoomList isV2={isV2} />
    </View>
  );
};
export default ClinicalProgressRoomContainer;
