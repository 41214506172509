import { isDummyCTReactivationProvider } from 'ts-frontend/entities/Therapist';
import { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { InRoomSchedulingDashboardTool } from 'inRoomScheduling';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import {
  EmergencyDisclaimer,
  View,
  Big,
  TSLogo,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  TextDS,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';

import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { useOpenModal } from '../utils/ModalsContextProvider';
import HelpfulTips from './HelpfulTipsContainer';
import BrazeContentCards from './BrazeContentCardsContainer';
import { extractRoomEntitiesFromState } from '../utils/extractRoomEntitiesFromState';
import useRoomsChangedEffect from '../hooks/useRoomsChangedEffect';
import { useMainActions, useMainState } from '../hooks/mainContext';
import { EMe } from '../entities/Me';
import { useHistory, useLocation } from '../core/routerLib/routerLib';
import RoomSummaryList from '../components/RoomSummaryList';
import DropdownMenuButton from '../components/DropdownMenuButton';
import { useTreatmentPlannerActions } from '../clinicalProgress/hooks/treatmentPlannerContext';
import { useSurveysActions } from '../clinicalProgress/hooks/surveysContext';
import ClinicalProgressDashboardTool from '../clinicalProgress/components/ClinicalProgressDashboardTool';
import UserSettingsSocketService from '../clientChat/utils/UserSettingsSocketService';
import useQueryOnboarding from '../onboarding/hooks/useQueryOnboarding';
import useRedirectOnboarding from '../hooks/useRedirectOnboarding';
import useNavShellWindowHeight from '@/hooks/useNavShellWindowHeight';
import {
  getIsOnboardingDismissed,
  getIsOnboardingDismissedTestAccount,
} from '../onboarding/util/onboardingStorage';
import useEligibilityWarning from '../EligibilityWarning/hooks/useEligibilityWarning';
import { trackInAppReview } from '../utils/analytics/events';

const { space200 } = spacing;

const Section: FunctionComponent<{ title: string; useNewNav?: boolean; style?: {} }> = ({
  title,
  useNewNav,
  children,
  style = {},
}) =>
  useNewNav ? (
    <View style={{ ...style }}>{children}</View>
  ) : (
    <View style={{ paddingLeft: 6, ...style }}>
      <Big as="h2" style={{ marginLeft: 14, marginBottom: 8 }}>
        {title}
      </Big>
      {children}
    </View>
  );

type Props = {
  isChatHidden?: boolean;
  roomID?: string;
};

const DashboardContainer: FunctionComponent<Props> = ({ roomID, isChatHidden }) => {
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useWindowWidthState();
  const mainState = useMainState();

  const me = mainState.me as EMe;
  const {
    adminConfigs,
    informedConsentStatus,
    roomsByID,
    cmsItems,
    therapistsByID,
    isDropdownMenuVisible,
  } = mainState;
  const { dispatchGetTreatmentPlanner } = useTreatmentPlannerActions();
  const { getInformedConsentStatus } = useMainActions();
  const { dispatchGetSurveys } = useSurveysActions();
  const [isAcking, setIsAcking] = useState(false);
  const [storedCMSItems, setStoredCMSItems] = useState(cmsItems);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [isReactivateCTUser, setIsReactivateCTUser] = useState(false);
  const openModal = useOpenModal();

  const isOnboardingDismissedTestAccount = roomID && getIsOnboardingDismissedTestAccount(roomID);
  const isOnboardingDismissed = roomID && getIsOnboardingDismissed(roomID);

  const { shouldShowEligibilityWarning } = useEligibilityWarning({
    roomID: Number(roomID),
  });

  const { brandRefreshChanges } = useFlags();
  const useNewNav = useNewMemberNav();

  const contentRef = useRef<HTMLDivElement | null>(null);

  const isCMSItemsEmpty = storedCMSItems.length === 0;

  const canOpenModal =
    location.pathname.startsWith('/room/') &&
    !location.pathname.includes('modal') &&
    !location.search;

  useEffect(() => {
    if (roomID && roomsByID[roomID]) {
      const { therapistID } = roomsByID[roomID];
      const { firstName } = therapistsByID[therapistID];
      setIsReactivateCTUser(
        isDummyCTReactivationProvider({
          therapistName: firstName,
        })
      );
    }
  }, [roomID, roomsByID, therapistsByID]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, []);

  const { data: onboardingData, isLoading: isLoadingOnboarding } = useQueryOnboarding({
    roomID: roomID ? Number(roomID) : undefined,
    userID: me.id,
  });
  const {
    data: treatmentIntakeInOnboardingActive,
    isSuccess: isSuccessTreatmentIntakeInOnboardingActive,
  } = useTSAdminConfig(AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING);

  useEffect(() => {
    const hasDisplayedCTReactivationPopUp = JSON.parse(
      sessionStorage.getItem('ctReactivationPopupOpened') || 'false'
    );

    const roomIDs = Object.keys(roomsByID);

    if (
      isReactivateCTUser &&
      canOpenModal &&
      !hasDisplayedCTReactivationPopUp &&
      (roomIDs.length === 1 ||
        (roomIDs.length > 1 &&
          !roomIDs.some((room) => roomsByID?.[room]?.status === RoomStatus.ACTIVE)))
    ) {
      openModal(`/room-reactivation/ct-reactivation`);
    }
  }, [isReactivateCTUser, canOpenModal, openModal, roomID, roomsByID]);

  useEffect(() => {
    if (shouldShowEligibilityWarning && canOpenModal) {
      openModal(`/eligibility-warning/${roomID}`);
    }
  }, [shouldShowEligibilityWarning, openModal, roomID, canOpenModal]);

  useRedirectOnboarding({ roomID: roomID ? Number(roomID) : undefined, disabled: !useNewNav });

  useEffect(() => {
    if (treatmentIntakeInOnboardingActive && roomID) {
      const hasOnboardingSteps = onboardingData && onboardingData?.steps.length > 0;
      // for useNewNav, onboarding is handled in useNavShell, using the useRedirectOnboarding hook
      if (!hasOnboardingSteps || useNewNav) {
        return;
      }
      // onboarding will show up if both true:
      // 1. QA account did not dismiss it
      // 2. User did not dismiss it OR user dismissed it and there are required onboarding steps
      const showOnboarding =
        !isOnboardingDismissedTestAccount &&
        (!isOnboardingDismissed || onboardingData.steps.some(({ isRequired }) => isRequired));
      if (!isLoadingOnboarding && showOnboarding) {
        history.push(`/room/${roomID}/onboarding`);
      }
    } else {
      getInformedConsentStatus(me.id);
    }
  }, [
    me.id,
    me.email,
    onboardingData,
    isLoadingOnboarding,
    getInformedConsentStatus,
    adminConfigs,
    history,
    roomID,
    treatmentIntakeInOnboardingActive,
    useNewNav,
    isOnboardingDismissedTestAccount,
    isOnboardingDismissed,
  ]);

  useEffect(() => {
    // Block IC modal from opening while another modal is open
    // KEVIN TODO - Investigate a way to queue up modals
    if (
      isSuccessTreatmentIntakeInOnboardingActive &&
      !treatmentIntakeInOnboardingActive &&
      informedConsentStatus.shouldSignConsent &&
      canOpenModal
    ) {
      const {
        roomID: roomIDToSign,
        showProviderInfo,
        informedConsentID,
        therapistID,
      } = informedConsentStatus;
      openModal(
        `/meet-your-provider?roomID=${roomIDToSign}&informedConsentID=${informedConsentID}&therapistID=${therapistID}&showProviderInfo=${showProviderInfo}`
      );
    }
  }, [
    informedConsentStatus,
    canOpenModal,
    roomID,
    openModal,
    roomID,
    treatmentIntakeInOnboardingActive,
    isSuccessTreatmentIntakeInOnboardingActive,
  ]);

  useRoomsChangedEffect(
    (ids) => {
      const updateUserSettings = (data) => {
        setIsAcking(data && data.sendMessagesReceipts);
      };
      if (ids.length) ids.forEach((id) => dispatchGetTreatmentPlanner(+id));
      dispatchGetSurveys();
      UserSettingsSocketService.instance().addListener(updateUserSettings);
      return () => {
        UserSettingsSocketService.instance().removeListener(updateUserSettings);
      };
    },
    [dispatchGetSurveys, dispatchGetTreatmentPlanner, roomID, therapistsByID]
  );

  const { outsideHeaderHeight, outsideFooterHeight } = useNavShellWindowHeight();

  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);

  return (
    <ResponsiveLayoutWithHeader
      ref={contentRef}
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      noHeaderLine={isMobile && useNewNav}
      renderHeader={() => (
        <PanelHeader
          style={brandRefreshChanges ? { paddingLeft: 0 } : undefined}
          renderLeft={() =>
            useNewNav ? (
              <View style={{ marginLeft: space200 }}>
                <TextDS variant="heading2xl">Messages</TextDS>
              </View>
            ) : (
              <>
                <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
                <DropdownMenuButton displayName={me.displayName} />
              </>
            )
          }
        />
      )}
      {...(isDropdownMenuVisible && { contentWrapperStyle: { overflowY: 'hidden' } })}
    >
      <Section
        title="Rooms"
        useNewNav={useNewNav}
        style={{ marginTop: useNewNav ? 0 : 29, marginBottom: 29 }}
      >
        <RoomSummaryList
          isChatHidden={isChatHidden}
          rooms={Object.values(roomsByID)}
          activeRoomID={Number(roomID)}
          isAcking={isAcking}
        />
      </Section>
      {!useNewNav && (
        <>
          <BrazeContentCards feed="dashboard" />
          {isCMSItemsEmpty ? null : <Section title="Helpful tips" />}
          {!!isCMSItemsEmpty && !isPlayerVisible ? null : (
            <HelpfulTips
              cmsItems={storedCMSItems}
              clientID={me.id}
              setCMSItems={setStoredCMSItems}
              isPlayerVisible={isPlayerVisible}
              setIsPlayerVisible={setIsPlayerVisible}
              isCMSItemsEmpty={isCMSItemsEmpty}
            />
          )}

          <Section title="Tools">
            <InRoomSchedulingDashboardTool
              numberOfRooms={Object.keys(roomsByID).length}
              closeRoute="room-details"
              subscriptions={Object.values(mainState.subscriptionsByID)}
              getTherapistByRoomID={(roomIDarg: number) =>
                extractRoomEntitiesFromState(mainState, roomIDarg).therapist
              }
              getRoomByRoomID={(roomIDarg: number) =>
                extractRoomEntitiesFromState(mainState, roomIDarg).room
              }
            />
            <ClinicalProgressDashboardTool />
          </Section>
          <EmergencyDisclaimer
            isLoggedIn
            style={{
              margin: 'auto 20px 40px',
              paddingTop: 24,
            }}
          />
        </>
      )}
    </ResponsiveLayoutWithHeader>
  );
};

export default DashboardContainer;
