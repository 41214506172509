import { useCallback, MouseEventHandler, useMemo } from 'react';
import { History } from 'history';
import moment from 'moment';
import { VideoCallAPIResponse, VIDEO_CALL_SESSION_STORAGE_KEY } from 'chat/types/videoCallTypes';
import { Booking } from 'ts-frontend/types';
import redirectToLiveSession, { LiveSessionState } from 'chat/utils/redirectToLiveSession';
import { useHistory } from '@/core/routerLib';
import { useOpenModal } from '@/utils/ModalsContextProvider';

const navigateToLiveSession = ({
  roomID,
  sessionState,
  history,
  useRedirect,
}: {
  roomID: number;
  sessionState: LiveSessionState;
  history: History;
  useRedirect?: boolean;
}) => {
  if (useRedirect) {
    redirectToLiveSession(roomID, sessionState);
  } else {
    sessionStorage.setItem(VIDEO_CALL_SESSION_STORAGE_KEY, JSON.stringify(sessionState));
    history.push(`/room/${roomID}?action=video-call`, sessionState);
  }
};

interface UseJoinSessionButtonOptions {
  activeSession: VideoCallAPIResponse | undefined;
  booking: Booking | undefined | null;
  roomID: number;
  useRedirect?: boolean;
  isBH: boolean;
}
const useJoinSession = ({
  activeSession,
  booking,
  roomID,
  useRedirect,
  isBH,
}: UseJoinSessionButtonOptions) => {
  const history = useHistory();
  const openModal = useOpenModal();

  const { modality: bookingModality, timekitBookingState, id: timekitBookingID } = booking || {};
  const {
    modality: sessionModality,
    videoCallID,
    therapist: { therapistFirstName, therapistLastName, userID: therapistUserID },
    booking: { startTime, creditMinutes, type: videoCreditType },
  } = activeSession || { therapist: {}, booking: {} };

  const sessionState = useMemo<LiveSessionState | null>(() => {
    if (
      videoCallID &&
      therapistUserID &&
      therapistFirstName &&
      therapistLastName &&
      startTime &&
      creditMinutes !== null &&
      creditMinutes !== undefined &&
      videoCreditType &&
      sessionModality
    ) {
      return {
        therapistUserID,
        roomID,
        therapistFirstName,
        therapistLastName,
        startTime,
        creditMinutes,
        videoCreditType,
        videoCallID,
        modality: sessionModality,
        tokenExpiresAt: moment(startTime)
          .add(creditMinutes || 30, 'minute')
          .toISOString(),
      };
    }
    return null;
  }, [
    videoCallID,
    therapistUserID,
    therapistFirstName,
    therapistLastName,
    startTime,
    creditMinutes,
    videoCreditType,
    sessionModality,
    roomID,
  ]);

  const isMessaging = bookingModality === 'messaging';
  const isAdHoc = !booking;
  const isEnabledLiveSession = !!sessionState;
  const isEnabledMessaging = !!(isMessaging && roomID);
  const isEnabledAdHoc = !!(isAdHoc && roomID && videoCallID);
  const isEnabledTentative = videoCallID && timekitBookingState === 'tentative';
  const isFree = videoCreditType === 'free';

  const onClickJoinSession = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const duration = creditMinutes || 30;
      if (!roomID) {
        return;
      }

      if (isMessaging) {
        history.push(`/room/${roomID}`);
      } else if (videoCallID && timekitBookingState === 'tentative') {
        const params = new URLSearchParams({
          isJoin: 'true',
          videoCallID: videoCallID.toString(),
          batch: 'true',
        });
        const route = `/in-room-scheduling/room/${roomID}/confirm-booking/booking/${timekitBookingID}?${params.toString()}`;
        openModal(route);
      } else if (videoCallID && isBH && isAdHoc && !isFree) {
        const params = new URLSearchParams({
          isJoin: 'true',
          videoCallID: videoCallID.toString(),
          batch: 'true',
          isBHAdHoc: 'true',
          adHocDuration: duration.toString(),
        });
        const route = `/in-room-scheduling/room/${roomID}/confirm-booking?${params.toString()}`;
        openModal(route);
      } else if (sessionState) {
        navigateToLiveSession({ roomID, sessionState, history, useRedirect });
      }
    },
    [
      openModal,
      history,
      roomID,
      creditMinutes,
      videoCallID,
      isMessaging,
      isAdHoc,
      timekitBookingState,
      timekitBookingID,
      isFree,
      sessionState,
      useRedirect,
      isBH,
    ]
  );

  return {
    onClickJoinSession,
    isDisabled:
      !isEnabledLiveSession && !isEnabledAdHoc && !isEnabledMessaging && !isEnabledTentative,
    isTentative: isEnabledTentative,
  };
};

export default useJoinSession;
