import { FunctionComponent, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useHistory, withRouter } from 'react-router';
import {
  ResponsiveLayoutWithHeader,
  PanelHeader,
  Large,
  styled,
  useEmotionTheme,
  TextDS,
  Text,
  spacing,
  Breadcrumb,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useFlag } from 'launchDarkly/FlagsProvider';
import { isDummyCanceledNoMatchesProvider } from 'ts-frontend/entities/Therapist';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useDeepLinkAction, DeepLinkAction } from '@/hooks/useDeepLink';
import { getUserData } from '@/auth/helpers/token';
import {
  trackWizardEvent,
  trackUserSettingsEvent,
  trackPaymentPlanEvent,
} from '@/utils/analytics/events';
import { useLocation } from '@/core/routerLib';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import useSubscriptions from '../hooks/useSubscriptions';
import useAccountDetails from '../hooks/useAccountDetails';
import MobileHeader from '../../components/MobileLeftHeader';
import MyAccountPanelWrapper from '../components/MyAccountPanelWrapper';
import ManageSubscriptionModal from '../components/ManageSubscriptionModal';
import UserEventsSocketService from '../../clientChat/utils/UserEventsSocketService';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import LoadingPanel from '../../2storybook/components/LoadingPanel';
import InfoPanel from '../../components/InfoPanel';
import getPanelWidth from '../utils/design';
import CardDetails from '../components/CardDetails';
import SubscriptionSection from '../components/SubscriptionSection';
import { ROOM_TYPE_TO_SERVICE_TYPE } from '../../whatToExpect/constants';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import { useCloseModal, useIsModal } from '@/utils/ModalsContextProvider';

const { space100, space200, space400 } = spacing;

const PAYMENT_ROOM_STATUSES = [
  RoomStatus.ACTIVE,
  RoomStatus.CANCELED,
  RoomStatus.FROZEN,
  RoomStatus.PAST_DUE,
  RoomStatus.NOT_RENEWING,
];

const breadcrumbRoutes = [
  { label: 'Account', path: '/home/account' },
  { label: 'Payment and plan', path: '' },
];

const StyledHeading = styled(Text)<{ isMobile: boolean }>(({ isMobile, theme: { colors } }) => {
  return {
    fontSize: isMobile ? 14 : 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: colors.permaLividBlueNew,
    margin: isMobile ? '10px 0' : 0,
  };
});
interface ManageSubscriptionHeaderProps {
  inNavShell: boolean;
  isMobile: boolean;
  isInFrame: boolean;
  isiOS: boolean;
  onBackButtonPress: () => void;
  onCloseButtonPress: () => void;
}
const ManageSubscriptionHeader = ({
  isMobile,
  inNavShell,
  isInFrame,
  isiOS,
  onBackButtonPress,
  onCloseButtonPress,
}: ManageSubscriptionHeaderProps) => {
  if (inNavShell && !isMobile) return null;
  if (inNavShell && isMobile)
    return (
      <PanelHeader
        renderLeft={() => (
          <MobileHeader title="" mobileTitle="" onBackButtonPress={onBackButtonPress} />
        )}
        dataQa="panelHeaderPaymentAndPlanCloseButton"
        onRightPress={isInFrame ? undefined : onCloseButtonPress}
      />
    );
  return (
    <PanelHeader
      renderLeft={() => (
        <MobileHeader
          centerTitle={isInFrame && isiOS}
          title="My Account"
          mobileTitle="Payment and plan"
          onBackButtonPress={onBackButtonPress}
        />
      )}
      dataQa="panelHeaderPaymentAndPlanCloseButton"
      onRightPress={isInFrame ? undefined : onCloseButtonPress}
    />
  );
};

interface OwnProps {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
  inNavShell?: boolean;
}

interface MatchParams {
  roomID: string;
}

type Props = OwnProps & RouteComponentProps<MatchParams>;

const StyledInfoPanel = styled(InfoPanel)(
  ({
    theme: {
      window,
      colors: { periwinkleGrey },
    },
  }) => {
    return {
      borderRadius: 10,
      borderTopColor: periwinkleGrey,
      borderRightColor: periwinkleGrey,
      borderLeftColor: periwinkleGrey,
      marginBottom: 15,
      minWidth: 335,
      maxWidth: getPanelWidth(window),
    };
  }
);

const ManageSubscriptionContainer: FunctionComponent<Props> = ({
  match,
  onCloseButtonPress,
  onBackButtonPress,
  inNavShell,
}) => {
  const [
    state,
    {
      dispatchOpenModal,
      dispatchCloseModal,
      getSubscriptions,
      pauseSubscription,
      resumeSubscription,
      undoCancelSubscription,
      undoCancelNonSubscription,
    },
  ] = useSubscriptions();
  const { getAllRooms } = useMainActions();
  const { roomsByID } = useMainState();
  const { isMobile } = useWindowWidthState();
  const location = useLocation();

  const queryRoomID = useMemo(
    () => new URLSearchParams(location.search).get('roomID') || '',
    [location]
  );

  const openModal = useOpenModalSafe();
  const closeModal = useCloseModal();
  const isModal = useIsModal();
  const useNewNav = useNewMemberNav();
  const history = useHistory();

  const { id: userID } = getUserData();
  const flagACKP = useFlag('addCoverageKeepProvider');

  const roomCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (useNewNav && isMobile && !isModal) {
      history.replace('/home');
      openModal('/home/account');
      openModal('/home/account/manage-subscription');
    }
  }, [useNewNav, isMobile, isModal, history, openModal]);

  const [
    {
      isLoading: isAccountDetailsLoading,
      isLinkLoaded,
      paymentDetails,
      successModalText,
      ...accountDetailsState
    },
    {
      updatePaymentDetails,
      onLoadStripeLink,
      dispatchPaymentMethodError,
      getPaymentDetails,
      dispatchSetSuccessModalText,
      dispatchClearError: dispatchClearAccountDetailsError,
    },
  ] = useAccountDetails();
  const {
    colors: { periwinkleGrey },
  } = useEmotionTheme();

  useEffect(() => {
    getSubscriptions();

    const userEventsSocketService = new UserEventsSocketService();
    userEventsSocketService.on('changedPayment', getSubscriptions);
    userEventsSocketService.on('changedRoomStatus', getSubscriptions);
    userEventsSocketService.on('changedSubscription', getSubscriptions);

    return () => {
      userEventsSocketService.off('changedPayment', getSubscriptions);
      userEventsSocketService.off('changedRoomStatus', getSubscriptions);
      userEventsSocketService.off('changedSubscription', getSubscriptions);
      userEventsSocketService.unmount(false);
    };
  }, [getSubscriptions]);

  useEffect(() => {
    getPaymentDetails();
  }, [getPaymentDetails]);

  useEffect(() => {
    getAllRooms(userID);
  }, [getAllRooms, userID]);

  const userEmail = state.subscriptions.length > 0 ? state.subscriptions[0].participantEmail : '';

  useDeepLinkAction(DeepLinkAction.CURRENT_SUBSCRIPTION)(
    useCallback(
      (onSuccess) => {
        // Checking for state.subscriptions.length since this is what renders the cards and sets the ref
        if (state.subscriptions.length > 0 && roomCardRef.current) {
          onSuccess();
          roomCardRef.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      },
      [state.subscriptions]
    )
  );
  useDeepLinkAction(DeepLinkAction.CANCELLATION_SWITCH_EXIT)(
    useMemo(() => {
      return { openModal, roomID: match.params.roomID || queryRoomID };
    }, [openModal, match, queryRoomID])
  );
  useDeepLinkAction(DeepLinkAction.PROVIDER_DEPARTING_SWITCH)(
    useMemo(() => {
      return { openModal, roomID: +match.params.roomID || +queryRoomID };
    }, [openModal, match, queryRoomID])
  );
  const [currentInteractionRoomID, setCurrentInteractionRoomID] = useState(0);

  const isB2BCurrentInteractionRoomID = (roomID) =>
    state.subscriptions.some((room) => room.id === roomID && room.subscription.isB2B);

  function handleChangeTherapistPress(roomID: number, therapistID: number) {
    const { accountType, status } = roomsByID[roomID];
    trackWizardEvent('Change Therapist', 'Self Serve Switch', {
      'User ID': userID,
      label: 'Change Therapist',
      Application: 'Self Serve Switch',
      eventPropertyValue: therapistID,
      accountType,
      roomStatus: status,
      Source: 'Payment Plan',
    });

    openModal('/switch-provider', { roomID, source: 'my-account', userID });
  }

  function handleUndoCancelSubscriptionPress(roomID: number) {
    dispatchOpenModal('');

    const subscription = state.subscriptions.find(({ id }) => id === roomID);
    const isCancelledPendingSubscription = isDummyCanceledNoMatchesProvider({
      therapistName: subscription?.therapist?.fullName || '',
    });
    const undoCancellationAction =
      isB2BCurrentInteractionRoomID(roomID) || isCancelledPendingSubscription
        ? undoCancelNonSubscription
        : undoCancelSubscription;
    undoCancellationAction(roomID);
  }

  function handleResumeSubscriptionPress(roomID: number) {
    dispatchOpenModal('');
    resumeSubscription(roomID);
  }

  function handlePauseTherapyPress(roomID: number) {
    const { accountType, status } = roomsByID[roomID];
    trackPaymentPlanEvent(userID, 'Pause Therapy', { accountType, roomStatus: status });

    setCurrentInteractionRoomID(roomID);
    dispatchOpenModal('pausePlan');
  }
  function handleViewSuperbills(roomID: number) {
    const { accountType, status } = roomsByID[roomID];
    trackPaymentPlanEvent(userID, 'View Superbills', { accountType, roomStatus: status });

    openModal(`/superbills/room/${roomID}`);
  }
  function handleChangePlanPress(roomID: number, therapistID: number, offerID: number) {
    useNewNav && isMobile
      ? closeModal({ navigateTo: 'changePlan', metadata: { roomID, offerID } })
      : openModal(`/room-offer/${roomID}/offer/${offerID}`);

    const { accountType, status } = roomsByID[roomID];
    trackWizardEvent('Change Plan', 'Change Plan', {
      'User ID': userID,
      label: 'Change Plan',
      eventPropertyValue: therapistID,
      accountType,
      roomStatus: status,
      Source: 'Payment Plan',
    });
  }

  function handleCancelPlanPress(roomID: number, therapistID: number, willCancelSessions: boolean) {
    const { accountType, status } = roomsByID[roomID];
    const currentSubscription = state.subscriptions.find(({ id }) => id === roomID);

    trackWizardEvent('Cancel Subscription', 'Cancellation Wizard', {
      'User ID': userID,
      label: 'Cancel Subscription',
      Application: 'Cancellation Wizard',
      eventPropertyValue: therapistID,
      accountType,
      roomStatus: status,
      Source: 'Payment Plan',
    });

    const isPendingSubscription = !!currentSubscription?.subscription.isPendingSubscription;
    const isB2B = isB2BCurrentInteractionRoomID(roomID);
    const currentCancellationRoute =
      isB2B || isPendingSubscription ? 'cancel-non-subscription' : 'cancel-subscription';

    if (currentSubscription?.subscription.isB2C && willCancelSessions) {
      if (useNewNav && isMobile) {
        closeModal({
          navigateTo: 'cancelSubscription',
          metadata: {
            roomID: Number(roomID),
            cancellationRoute: 'cancel-subscription',
            param: 'sessions-to-cancel',
          },
        });
      } else {
        openModal(`/cancel-subscription/room/${roomID}/source/my-account/sessions-to-cancel`);
      }
    } else if (useNewNav && isMobile) {
      closeModal({
        navigateTo: 'cancelSubscription',
        metadata: {
          roomID: Number(roomID),
          cancellationRoute: currentCancellationRoute,
        },
      });
    } else {
      openModal(`/${currentCancellationRoute}/room/${roomID}/source/my-account`);
    }
  }

  const handleOnCloseModal = () => {
    getAllRooms(userID);
    dispatchCloseModal();
    if (accountDetailsState.error) {
      dispatchClearAccountDetailsError();
    }
    if (successModalText !== '') {
      dispatchSetSuccessModalText('');
    }
  };

  const handleNeedHelpPress = () => {
    if (isMobile && inNavShell) {
      closeModal({ navigateTo: 'refundPurchase' });
    } else {
      openModal(`/refund-purchase/source/payment-and-plan`);
    }
  };

  const handleUpdateCoveragePress = useCallback(
    (roomID: number, previousTherapistId: number) => {
      const { roomType } = roomsByID[roomID];
      trackUserSettingsEvent(userID, 'Update Coverage', 'Payment Plan');
      if (flagACKP) {
        const queryParams = new URLSearchParams({
          updateCoverageRoomID: roomID.toString(),
          source: 'update-coverage',
          serviceType: ROOM_TYPE_TO_SERVICE_TYPE[roomType],
          previousTherapistId: previousTherapistId.toString(),
        });
        return openModal(`/what-to-expect?${queryParams.toString()}`);
      }
      return openModal(`/eligibility-widget/room/${roomID}`);
    },
    [openModal, roomsByID, userID, flagACKP]
  );

  const isInFrame = ReactFrameService.instance().isInFrame();
  const isiOS = ReactFrameService.isiOS();

  const pendingPlans = useMemo(
    () =>
      state.subscriptions.filter(
        (subscription) =>
          subscription.status === RoomStatus.PENDING_SUBSCRIPTION ||
          subscription.status === RoomStatus.MBA
      ),
    [state.subscriptions]
  );

  const activePlans = useMemo(
    () =>
      state.subscriptions.filter((subscription) =>
        [
          RoomStatus.ACTIVE,
          RoomStatus.FREE_TRIAL,
          RoomStatus.NOT_RENEWING,
          RoomStatus.PAST_DUE,
          RoomStatus.FROZEN,
          RoomStatus.FREE_TRIAL_CHATBOT,
        ].includes(subscription.status)
      ),
    [state.subscriptions]
  );

  const inactivePlans = useMemo(
    () =>
      state.subscriptions.filter(
        (subscription) =>
          subscription.status === RoomStatus.CLOSED || subscription.status === RoomStatus.CANCELED
      ),
    [state.subscriptions]
  );

  const updateDetailsOnPress = () => {
    if (useNewNav) {
      const paymentRoomID = Object.keys(roomsByID).find((roomID) => {
        const paymentRoom = roomsByID[roomID];
        return (
          PAYMENT_ROOM_STATUSES.includes(paymentRoom.status) &&
          ['b2c', 'bh'].includes(paymentRoom.accountType)
        );
      });
      const updatePaymentRoomID =
        match.params.roomID || queryRoomID || paymentRoomID || Object.keys(roomsByID)[0];
      closeModal({
        navigateTo: 'updatePaymentMethod',
        metadata: { roomID: Number(updatePaymentRoomID) },
      });
    } else {
      openModal(`/payment-wizard/room/${match.params.roomID}?source=updatePaymentMethod`);
    }
  };

  const baseSubscriptionSectionProps = {
    isMobile,
    handleViewSuperbills,
    handleNeedHelpPress,
    roomCardRef,
    handlePauseButtonPress: handlePauseTherapyPress,
    handleChangePlanButtonPress: handleChangePlanPress,
    handleCancelPlanButtonPress: handleCancelPlanPress,
    handleChangeProviderButtonOnPress: handleChangeTherapistPress,
    resumeSubscriptionOnPress: handleResumeSubscriptionPress,
    undoCancelSubscriptionOnPress: handleUndoCancelSubscriptionPress,
    updatePaymentDetailsOnPress: () => dispatchOpenModal('updatePaymentDetails'),
    currentRoomID: Number(match.params.roomID || queryRoomID),
  };

  return (
    <ResponsiveLayoutWithHeader
      noHeaderLine={inNavShell}
      renderHeader={
        inNavShell && !isMobile
          ? null
          : () => (
              <ManageSubscriptionHeader
                inNavShell={!!inNavShell}
                isMobile={isMobile}
                isInFrame={isInFrame}
                isiOS={isiOS}
                onBackButtonPress={onBackButtonPress}
                onCloseButtonPress={onCloseButtonPress}
              />
            )
      }
    >
      <MyAccountPanelWrapper
        alwaysRow={isInFrame}
        title={inNavShell ? '' : 'Payment and plan'}
        hideMenu={inNavShell}
      >
        {inNavShell && !isMobile && <Breadcrumb routes={breadcrumbRoutes} />}
        {inNavShell && (
          <TextDS style={{ marginTop: space100 }} variant="heading2xl">
            Payment and plan
          </TextDS>
        )}
        {inNavShell && (
          <TextDS style={{ marginTop: space400, marginBottom: space200 }} variant="headingLg">
            Payment
          </TextDS>
        )}
        {isAccountDetailsLoading ? (
          <LoadingPanel style={{ height: 113 }} />
        ) : (
          <StyledInfoPanel style={{ borderTopColor: periwinkleGrey, height: 113 }}>
            <CardDetails paymentDetails={paymentDetails} onUpdatePress={updateDetailsOnPress} />
          </StyledInfoPanel>
        )}
        {state.error && <Large>Subscription information currently unavailable</Large>}
        {state.isLoading ? (
          <LoadingPanel />
        ) : (
          <>
            <SubscriptionSection
              {...baseSubscriptionSectionProps}
              heading={
                inNavShell ? (
                  <TextDS variant="headingLg">Pending plans</TextDS>
                ) : (
                  <StyledHeading as="h2" isMobile={isMobile}>
                    PENDING PLANS
                  </StyledHeading>
                )
              }
              filteredSubscriptions={pendingPlans}
            />

            <SubscriptionSection
              {...baseSubscriptionSectionProps}
              heading={
                inNavShell ? (
                  <TextDS variant="headingLg">Active plans</TextDS>
                ) : (
                  <StyledHeading as="h2" isMobile={isMobile}>
                    ACTIVE PLANS
                  </StyledHeading>
                )
              }
              filteredSubscriptions={activePlans}
              handleUpdateCoverageButtonPress={handleUpdateCoveragePress}
            />

            <SubscriptionSection
              {...baseSubscriptionSectionProps}
              heading={
                inNavShell ? (
                  <TextDS variant="headingLg">Inactive plans</TextDS>
                ) : (
                  <StyledHeading as="h2" isMobile={isMobile}>
                    INACTIVE PLANS
                  </StyledHeading>
                )
              }
              filteredSubscriptions={inactivePlans}
              isInactivePlans
            />
          </>
        )}
        {state.isModalOpen && (
          <ManageSubscriptionModal
            isLoading={state.isLoading || isAccountDetailsLoading}
            error={accountDetailsState.error}
            openModalType={state.openModalType}
            successModalText={state.successModalText || successModalText}
            userEmail={userEmail}
            closeModal={handleOnCloseModal}
            pausePlan={() => pauseSubscription(currentInteractionRoomID)}
            updatePaymentDetails={(data) => updatePaymentDetails(data, true)}
            onLoadStripeLink={onLoadStripeLink}
            isLinkLoaded={isLinkLoaded}
            dispatchPaymentMethodError={dispatchPaymentMethodError}
          />
        )}
      </MyAccountPanelWrapper>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(ManageSubscriptionContainer);
