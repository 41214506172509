import { FunctionComponent, useState, useEffect, useRef } from 'react';
import {
  View,
  TouchableView,
  Large,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  Spinner,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useMainState, useMainActions } from '@/hooks/mainContext';
import { ImplicitBusinessHoursByDay } from '@/clientChat/reducers/inPlatformMatchingReducer';
import { ID_PROVIDER_DETAILS_PANEL } from '@/utils/IDConstants';
import { allowReview } from '@/switchWizard/utils/switchWizardApiHelper';
import { getUserData } from '../auth/helpers/token';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import PanelTherapistBlock from '../components/PanelTherapistBlock/index';
import extractRoomEntities from '../utils/extractRoomEntitiesFromState';
import ApiHelper from '../utils/ApiHelper';
import useObjectState from '../myAccount/hooks/useObjectState';
import styled from '../core/styled';
import TherapistDetailsViewPT from '../components/TherapistDetailsViewPT/index';
import useA11y from './TherapistDetails.a11y';
import { useCloseModal } from '../utils/ModalsContextProvider';

const LightButton = styled(View)({
  boxShadow: '0 3px 11px -3px rgba(16,109,149,0.30)',
  borderRadius: 22,
  height: 44,
  width: 170,
  alignItems: 'center',
  justifyContent: 'center',
});

const ReviewProviderButtonText = styled(Large)(({ theme: { colors } }) => {
  return {
    color: colors.greenText,
  };
});
type Props = RouteComponentProps<{ roomID: string }> & {
  isChatHidden?: boolean;
  isMobile?: boolean;
  isModal?: boolean;
};

const TherapistDetailsContainer: FunctionComponent<Props> = ({
  history,
  match,
  isChatHidden,
  isMobile,
  isModal,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [reviewAllowed, setReviewAllowed] = useState(false);
  const closeModal = useCloseModal();

  const useNewNav = useNewMemberNav();

  const handleOnRoomPanelClosePress = () => {
    if (isModal) {
      closeModal();
    } else {
      history.push(`/room/${match.params.roomID}`);
    }
  };

  const { getAllRooms } = useMainActions();
  const { id: userID } = getUserData();
  const { therapist, room } = extractRoomEntities(useMainState(), Number(match.params.roomID));
  const [businessHoursByDay, setBusinessHoursByDay] = useObjectState<
    ImplicitBusinessHoursByDay | undefined
  >(undefined);

  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  useEffect(() => {
    (async () => {
      const allowReviewResponse = await allowReview(Number(match.params.roomID));

      setReviewAllowed(allowReviewResponse.allow || false);
    })();
  }, [match.params.roomID]);

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );

  useEffect(() => {
    if (therapist?.id) {
      api
        .getImplicitBusinessHours(therapist.id)
        .then((res) => {
          if (res.businessHours.length) {
            setBusinessHoursByDay(prepareDataByTimezone(res.businessHours));
          }
        })
        .catch((err) => {
          setError(err);
        });
    } else if (userID) {
      getAllRooms(userID);
    }
  }, [api, setBusinessHoursByDay, setError, therapist?.id, getAllRooms, userID]);

  const { skipNavs } = useA11y(history, match);

  if (!therapist?.id || !room?.roomID) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
        }}
      >
        <Spinner />
      </View>
    );
  }

  const { startDate, endDate } = therapist.unavailabilityPeriod;
  const timeOffText =
    startDate && endDate
      ? `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`
      : 'No time off scheduled';

  const handleOnLeaveAReviewClick = () => {
    history.push({
      pathname: `/room/${match.params.roomID}/review-therapist`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const titleText = `${therapist.title} ${therapist.firstName} ${therapist.lastName}'s profile`;

  return (
    <ResponsiveLayoutWithHeader
      title={titleText}
      panelId={ID_PROVIDER_DETAILS_PANEL}
      renderHeader={() => (
        <PanelHeader
          title="Provider Profile"
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isChatHidden={isChatHidden}
          isMobile={isMobile}
          useNewNavHeader={useNewNav}
        />
      )}
    >
      <View
        align="center"
        style={{
          paddingBottom: 20,
          overflowX: 'hidden',
        }}
      >
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            alignItems: 'center',
            maxWidth: 523,
          }}
        >
          <View style={{ marginTop: 30, marginBottom: 15 }}>
            <PanelTherapistBlock
              therapist={therapist}
              room={room}
              avatarSize={135}
              playButton
              licenses
            />
          </View>
          {reviewAllowed && (
            <TouchableView onPress={handleOnLeaveAReviewClick} aria-label="Open therapist review">
              <LightButton>
                <ReviewProviderButtonText variant="largeBoldWideGreen">
                  Leave a review
                </ReviewProviderButtonText>
              </LightButton>
            </TouchableView>
          )}
          <Large style={{ marginTop: 23, wordBreak: 'break-word' }}>
            {therapist.publicDetails}
          </Large>

          <TherapistDetailsViewPT
            therapist={therapist}
            businessHoursByDay={businessHoursByDay}
            timeOffText={timeOffText}
            error={error}
          />
        </View>
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(TherapistDetailsContainer);
