import React, { useMemo, useRef } from 'react';
import { Moment } from 'moment';

import {
  Col,
  ChevronLeft,
  Grid,
  Row,
  TouchableView,
  View,
  spacing,
} from '@talkspace/react-toolkit';
import useInfiniteScroll from '@talkspace/react-toolkit/src/hooks/useInfiniteScroll';

import { useHistory } from '@/core/routerLib';

import CommunityReferenceThreeDotMenu from './CommunityReferenceThreeDotMenu';
import CommunityPostComment from './CommunityPostComment';
import UserTag from './UserTag';
import CommunityFeedEnd from './CommunityFeedEnd';
import MoreResultsLoader from './MoreResultsLoader';
import CommentingEndedBanner from './CommentingEndedBanner';
import CreateCommentInput from './CreateCommentInput';
import { CommunityReferenceTypeEnum } from '../types';
import PostText from './PostText';
import PostToolbar from './PostToolbar';

const { space100, space150, space200, space800 } = spacing;

interface Props {
  post: Amity.Post;
  comments: Amity.Comment[] | undefined;
  isLoading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  commentingEndedAt: Moment | null;
}
const CommunityPostDetails = ({
  post,
  comments,
  isLoading,
  hasMore,
  onLoadMore,
  commentingEndedAt,
}: Props) => {
  const history = useHistory();
  const lastCommentRef = useRef<HTMLDivElement | null>(null);
  const commentInputRef = useRef<HTMLInputElement>(null);

  const isLoadingMore = useMemo(
    () => isLoading && comments && comments.length > 0,
    [isLoading, comments]
  );

  useInfiniteScroll({ bottomElementRef: lastCommentRef, hasMore, onLoadMore });

  return (
    <View style={{ position: 'relative' }}>
      <View
        row
        align="center"
        justify="space-between"
        style={{ width: '100%', padding: `${space150}px ${space200}px` }}
      >
        <TouchableView onPress={() => history.goBack()} dataQa="backButton">
          <ChevronLeft colorType="brand" />
        </TouchableView>
        {!isLoading && (
          <CommunityReferenceThreeDotMenu
            reference={post}
            referenceType={CommunityReferenceTypeEnum.POST}
          />
        )}
      </View>
      <Grid>
        <Row>
          <Col>
            {!isLoading && (
              <View style={{ padding: `${space100}px 0` }}>
                <UserTag user={post.creator} />
                <PostText post={post} style={{ padding: `${space100}px 0` }} />
                <PostToolbar post={post} commentInputRef={commentInputRef} />
                {commentingEndedAt && (
                  <CommentingEndedBanner commentingEndedAt={commentingEndedAt} />
                )}
              </View>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {comments &&
              comments.map((comment, i) => {
                const isLast = i === comments.length - 1;
                return (
                  <CommunityPostComment
                    key={`comment-${comment.commentId}`}
                    ref={isLast ? lastCommentRef : undefined}
                    comment={comment}
                  />
                );
              })}
            {isLoadingMore && <MoreResultsLoader text="More comments incoming..." />}
            {!isLoading && !hasMore && (
              <CommunityFeedEnd style={{ marginBottom: space800 + space200 }} />
            )}
          </Col>
        </Row>
        {!commentingEndedAt && <CreateCommentInput ref={commentInputRef} postID={post?.postId} />}
      </Grid>
    </View>
  );
};

export default CommunityPostDetails;
