import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { QueryClient, QueryClientProvider } from 'react-query';
import ReactDOM from 'react-dom';
import { View, A11yProvider } from '@talkspace/react-toolkit';
import { i18nInit, initReactI18next } from '@talkspace/i18n';
import configureAWSAmplify from '@talkspace/auth/configureAWSAmplify';
import { TranslationProvider } from '@talkspace/i18n/hooks/translationContext';
import { newRelicInit } from 'ts-ionic/plugins/newRelic';
import { NetworkProvider } from 'ts-ionic/plugins/network';
import apiWrapper from '@/core/api/apiWrapper';
import { initTracker } from './utils/analytics/eventTracker';
import * as serviceWorker from './serviceWorker';
import 'whatwg-fetch';
import 'normalize.css';
import './styles.css';
import ReactFrameService from './auth/reactFrame/ReactFrameService';
import '@talkspace/react-toolkit/assets/fonts/Roboto/roboto-font.css';
import '@talkspace/react-toolkit/assets/fonts/Exposure/exposure-font.css';
import '@talkspace/react-toolkit/assets/fonts/Quarto/quarto-font.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import sessionStorage from './core/storage/sessionStorage';
import Router from './Router';
import configs from './utils/configs';
// import { ReactQueryDevtools } from 'react-query/devtools'

newRelicInit(configs.newRelic, ('NRENUMInit' in window && window.NRENUMInit) || (() => {}));
configureAWSAmplify();
initTracker(apiWrapper);
const queryClient = new QueryClient();
i18nInit(initReactI18next, configs.i18n.staticPath);

ReactDOM.render(
  <>
    <View
      style={{
        position: 'fixed',
        top: -200,
        height: 200,
        width: '100%',
      }}
    />
    <A11yProvider storage={sessionStorage}>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <NetworkProvider>
            <Router />
          </NetworkProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </A11yProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactFrameService.instance();
