import { createContext, useState, useCallback, useContext } from 'react';

export type ToastType = 'success' | 'error';

interface ToastState {
  isToastVisible: boolean;
  type: ToastType;
  text: string;
  durationMs: number;
  showToast: (toast: { type: ToastType; text: string; durationMs?: number }) => void;
  clearToast: () => void;
}

const ToastContext = createContext<ToastState | undefined>(undefined);

const ToastProvider = ({ children }) => {
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const [type, setType] = useState<ToastType>('success');
  const [text, setText] = useState<string>('');
  const [durationMs, setDurationMs] = useState<number>(3000);

  const showToast = useCallback<ToastState['showToast']>((toast) => {
    setType(toast.type);
    setText(toast.text);
    if (toast.durationMs) {
      setDurationMs(toast.durationMs);
    }
    setIsToastVisible(true);
  }, []);

  const clearToast = useCallback<ToastState['clearToast']>(() => {
    setIsToastVisible(false);
  }, []);
  return (
    <ToastContext.Provider
      value={{
        isToastVisible,
        type,
        text,
        showToast,
        clearToast,
        durationMs,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToastState = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('toast context must be used within ToastProvider');
  }
  return context;
};

export default ToastProvider;
