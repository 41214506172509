import { useCallback, useState, useEffect } from 'react';
import * as React from 'react';
import {
  View,
  Button,
  TabRadioGroup,
  ScrollView,
  useEmotionTheme,
  spacing,
  SpacingView,
  useWindowWidthState,
  StickyDrawerParentContainer,
  StickyDrawer,
  TextDS,
} from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { useMainState } from '../../../hooks/mainContext';
import { UpcomingSurvey } from '../../reducers/surveys';
import { getProgressMessageText } from '../ClinicalProgressDashboardTool';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import RoomSelectionCard from '../../../2storybook/components/RoomSelectionCard';
import { sortRoomsByLastMessageDate } from '../../../utils/extractRoomEntitiesFromState';
import { trackNavPageView } from '../../../utils/analytics/events';

const { space200, space400, space800 } = spacing;

const getMessageText = (
  upcomingSurveysByRoomID: { [roomID: string]: UpcomingSurvey[] } = {},
  roomID: number
) => {
  const { progressMessageLine1, progressMessageLine2 } = getProgressMessageText(
    upcomingSurveysByRoomID[roomID] || [],
    roomID
  );
  return `${progressMessageLine1} \n${progressMessageLine2}`;
};

const ID_THERAPIST_NAME = 'ID_THERAPIST_NAME';
const ID_CLINICAL_PROGRESS = 'ID_CLINICAL_PROGRESS';

const ClinicalProgressRoomList = ({
  location,
  isV2,
}: { isV2?: boolean } & RouteComponentProps<
  {},
  {},
  {
    currentRoomID: number;
    upcomingSurveysByRoomID?: { [roomID: string]: UpcomingSurvey[] };
  }
>) => {
  const closeModal = useCloseModal();
  const { colorRoles } = useEmotionTheme();
  const routeState = location.state || {};
  const { upcomingSurveysByRoomID, currentRoomID } = routeState;
  const [checkedRoom, setCheckedRoom] = useState<number>(currentRoomID);
  const { roomsByID, therapistsByID } = useMainState();
  const rooms = Object.values(roomsByID).sort(sortRoomsByLastMessageDate);
  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    trackNavPageView('clinical-progress-select');
  }, []);

  const handleSubmit = useCallback(() => {
    closeModal({
      navigateTo: 'clinicalProgress',
      metadata: { roomID: checkedRoom },
    });
  }, [checkedRoom, closeModal]);

  return (
    <StickyDrawerParentContainer>
      <View style={{ padding: space200, textAlign: 'center' }} align="center">
        <SpacingView centerAlign itemSpacing="space150">
          <TextDS variant="heading2xl">You’re tracking progress with multiple providers</TextDS>
          <TextDS variant="body">Select which progress area you’d like to view</TextDS>
        </SpacingView>
      </View>

      {isV2 ? (
        <>
          <View justify="center" row>
            <View style={{ width: 308 }}>
              <TabRadioGroup
                legendText="Select a room to view clinical progress"
                initialSelection={!!checkedRoom}
              >
                <SpacingView>
                  {rooms.map((room, i) => (
                    <RoomSelectionCard
                      room={room}
                      therapist={therapistsByID[room.therapistID]}
                      setSelectedRoom={setCheckedRoom}
                      selectedRoom={checkedRoom}
                      tinyText={getMessageText(upcomingSurveysByRoomID, room.roomID)}
                      key={room.roomID}
                      ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
                      ariaDescribedBy={`${ID_CLINICAL_PROGRESS}-${i}`}
                      isTabFirstOption={i === 0 && !checkedRoom}
                    />
                  ))}
                </SpacingView>
              </TabRadioGroup>
            </View>
          </View>
          {isMobile ? (
            <StickyDrawer>
              <Button
                text="Continue"
                onPress={handleSubmit}
                disabled={!checkedRoom}
                style={{ width: '100%' }}
              />
            </StickyDrawer>
          ) : (
            <View
              style={{
                paddingTop: space200,
                paddingBottom: isMobile ? undefined : space800,
                position: 'static',
                bottom: 0,
                backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
                width: '100%',
              }}
              align="center"
            >
              <Button
                text="Continue"
                onPress={handleSubmit}
                disabled={!checkedRoom}
                style={{ width: '100%' }}
              />
            </View>
          )}
        </>
      ) : (
        <>
          <ScrollView
            style={{ maxHeight: '40vh', minWidth: 345, paddingBottom: space800 + space400 }}
          >
            <TabRadioGroup
              legendText="Select a room to view clinical progress"
              initialSelection={!!checkedRoom}
            >
              <SpacingView>
                {rooms.map((room, i) => (
                  <RoomSelectionCard
                    room={room}
                    therapist={therapistsByID[room.therapistID]}
                    setSelectedRoom={setCheckedRoom}
                    selectedRoom={checkedRoom}
                    tinyText={getMessageText(upcomingSurveysByRoomID, room.roomID)}
                    key={room.roomID}
                    ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
                    ariaDescribedBy={`${ID_CLINICAL_PROGRESS}-${i}`}
                    isTabFirstOption={i === 0 && !checkedRoom}
                  />
                ))}
              </SpacingView>
            </TabRadioGroup>
          </ScrollView>
          <Button text="Continue" onPress={handleSubmit} style={{ marginTop: 20, width: 335 }} />
        </>
      )}
    </StickyDrawerParentContainer>
  );
};

export default withRouter(ClinicalProgressRoomList);
