import { useMemo } from 'react';

import { OverflowMenu, View } from '@talkspace/react-toolkit';

import { useToastState } from '@talkspace/react-toolkit/src/hooks/ToastProvider';
import Toast from '@talkspace/react-toolkit/src/designSystems/components/Toast';
import { useCommunityState } from '../hooks/CommunityContext';
import useIsCommunityEntityReported from '../hooks/useIsCommunityEntityReported';
import { deletePost, deleteComment, createReport } from '../utils/amity';
import { CommunityReferenceTypeEnum } from '../types';
import useSharePostWithTherapist from '../hooks/useSharePostWithTherapist';

import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';
import { COMMUNITY_TOASTS } from '../constants';

interface Props {
  reference: Amity.Post | Amity.Comment;
  referenceType: CommunityReferenceTypeEnum;
}
const CommunityReferenceThreeDotMenu = ({ reference, referenceType }: Props) => {
  const { userID, dailyPrompt } = useCommunityState();
  const {
    _id: referenceID,
    creator: { userId: creatorUserID },
    flagCount,
  } = reference;

  const { isReportedByMe } = useIsCommunityEntityReported({
    referenceType,
    referenceID,
    flagCount,
  });
  const isOwnReference = useMemo(() => String(userID) === creatorUserID, [userID, creatorUserID]);

  const { isToastVisible, showToast } = useToastState();
  const { sidebarWidth, outsideHeaderHeight } = useNavShellWindowHeight();

  const { isLoading: isLoadingShareWithTherapist, sharePostWithTherapist } =
    useSharePostWithTherapist({
      userID,
      dailyPrompt,
      responseText: reference.data.text,
    });

  const menuOptions = useMemo(() => {
    if (isOwnReference) {
      if (referenceType === CommunityReferenceTypeEnum.POST) {
        return [
          {
            label: 'Delete post',
            dataQa: 'deletePost',
            callback: () => {
              deletePost({ postID: referenceID }).then(() => {
                showToast(COMMUNITY_TOASTS.POST_DELETED);
              });
            },
          },
          {
            label: 'Share with my therapist',
            dataQa: 'sharePostWithTherapist',
            callback: () => {
              if (!isLoadingShareWithTherapist) {
                sharePostWithTherapist().then(() => {
                  showToast(COMMUNITY_TOASTS.POST_SHARED_WITH_THERAPIST);
                });
              }
            },
          },
        ];
      }

      if (referenceType === CommunityReferenceTypeEnum.COMMENT) {
        return [
          {
            label: 'Delete comment',
            dataQa: 'deleteComment',
            callback: () => {
              deleteComment({ commentID: referenceID }).then(() => {
                showToast(COMMUNITY_TOASTS.COMMENT_DELETED);
              });
            },
          },
        ];
      }
    }

    if (!isReportedByMe) {
      if (referenceType === CommunityReferenceTypeEnum.POST) {
        return [
          {
            label: 'Report post',
            dataQa: 'reportPost',
            callback: () => {
              createReport({ referenceType, referenceID }).then(() => {
                showToast(COMMUNITY_TOASTS.POST_REPORTED);
              });
            },
          },
        ];
      }
      if (referenceType === CommunityReferenceTypeEnum.COMMENT) {
        return [
          {
            label: 'Report comment',
            dataQa: 'reportComment',
            callback: () => {
              createReport({ referenceType, referenceID }).then(() => {
                showToast(COMMUNITY_TOASTS.COMMENT_REPORTED);
              });
            },
          },
        ];
      }
    }
    return [];
  }, [
    isLoadingShareWithTherapist,
    isOwnReference,
    isReportedByMe,
    referenceID,
    referenceType,
    sharePostWithTherapist,
    showToast,
  ]);

  if (menuOptions.length === 0) return <View style={{ width: 28, height: 28 }} />;

  return (
    <>
      <OverflowMenu
        hasTrayBackdrop
        options={menuOptions}
        dataQa={`${referenceType}-three-dots-menu`}
      />
      {isToastVisible && (
        <Toast
          top={outsideHeaderHeight}
          left={sidebarWidth}
          width={`calc(100vw - ${sidebarWidth}px)`}
        />
      )}
    </>
  );
};

export default CommunityReferenceThreeDotMenu;
