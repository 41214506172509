import TeenspaceProfileSetupContainer from '../../community/teenspaceOnboarding/containers/TeenspaceProfileSetupContainer';

interface Props {
  handleOnClose?: () => void;
}

const PersonalInformationEditTeenspaceCommunityContainer: React.VFC<Props> = ({
  handleOnClose,
}) => <TeenspaceProfileSetupContainer isPersonalInfoModal handleOnClose={handleOnClose} />;

export default PersonalInformationEditTeenspaceCommunityContainer;
