import { useEffect, useState, useMemo } from 'react';
import { getScreenSafeAreaInsets, TextDS, useEmotionTheme, View } from '../../..';
import { useToastState, type ToastType } from '../../../hooks/ToastProvider';
import { CircleCheck, CircleExclamation } from '../../icons';

const ANIMATION_TIME_MS = 300;

interface Props {
  top?: number;
  left?: number;
  width?: string | number;
}
const Toast = ({ top, left, width }: Props) => {
  const { spacing, colorRoles } = useEmotionTheme();
  const { type, text, durationMs, clearToast } = useToastState();
  const safeAreaInsets = getScreenSafeAreaInsets();

  const typeToLookConfig: { [key in ToastType]: { icon: JSX.Element; color: string } } =
    useMemo(() => {
      return {
        success: {
          icon: <CircleCheck colorType="info" />,
          color: colorRoles.surfaces.infoSubtle,
        },
        error: {
          icon: <CircleExclamation colorType="critical" />,
          color: colorRoles.surfaces.criticalSubtleDefault,
        },
      };
    }, [colorRoles]);

  const [opacity, setOpacity] = useState<0 | 1>(0);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  // start opening animation on mount
  useEffect(() => {
    setOpacity(1);
  }, []);

  // start closing animation after durationMs
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsClosing(true);
      setOpacity(0);
    }, durationMs);

    return () => clearTimeout(timeout);
  }, [durationMs]);

  // unmount when animation finishes
  useEffect(() => {
    let timeout;
    if (isClosing) {
      timeout = setTimeout(() => {
        clearToast();
      }, ANIMATION_TIME_MS);
    }
    return () => clearTimeout(timeout);
  }, [clearToast, isClosing]);

  return (
    <View
      row
      align="center"
      justify="center"
      style={{
        zIndex: 1000,
        position: 'fixed',
        top: top || safeAreaInsets.top,
        left: left || safeAreaInsets.left,
        width: width || '100%',
        backgroundColor: typeToLookConfig[type].color,
        padding: spacing('space150'),
        opacity,
        transition: `opacity ${ANIMATION_TIME_MS}ms ease`,
      }}
    >
      {typeToLookConfig[type].icon}
      <TextDS variant="bodySm" style={{ marginLeft: spacing('space100') }}>
        {text}
      </TextDS>
    </View>
  );
};

export default Toast;
