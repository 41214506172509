import { createContext, useState, useContext, useEffect } from 'react';
import moment from 'moment';

import { useIsTeenspaceActive } from 'launchDarkly/hooks';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { DailyPrompt, CommunityFeedTypeEnum } from '../types';
import { getUserData } from '@/auth/helpers/token';
import useQueryCommunityUserData from './useQueryCommunityUserData';
import useQueryDailyPrompt from './useQueryDailyPrompt';
import useQueryCommunityFilteredPostCount from './useQueryCommunityFilteredPostCount';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';
import { hasTeenspaceCommunity, getAge, getCommunityFeedFilterTags } from '../utils/helpers';
import useAmityLogin from './useAmityLogin';
import appConfig from '@/utils/configs';
import useQueryGetClient from '../../hooks/useQueryGetClient';
import { useLocation } from '@/core/routerLib';

const COMMUNITY_BASE_ROUTES = ['/home/teenspace', '/community'];

interface CommunityUserDataState {
  accessToken: string | undefined;
  communityID: string | undefined;
  userID: string;
  displayName: string | undefined;
  clientTimezone: string;
  clientState: string | undefined;
  clientAge: number | undefined;
  isTeenspaceEligible: boolean;
  isConnected: boolean;
  isConnectionError: boolean;
  dailyPrompt: DailyPrompt | undefined;
  isLoadingDailyPrompt: boolean;
  replyCount: number | undefined;
  isTeenspaceOnboarded: boolean;
  isLoading: boolean;
}

const CommunityContext = createContext<CommunityUserDataState | undefined>(undefined);

const CommunityProvider = ({ children }) => {
  const { id: userID } = getUserData();
  const { pathname } = useLocation();
  const isTeenspaceActive = useIsTeenspaceActive();
  const isInCommunityRoute = COMMUNITY_BASE_ROUTES.some((baseRoute) =>
    pathname.startsWith(baseRoute)
  );
  const disabled = !(isTeenspaceActive && isInCommunityRoute);
  const [isTeenspaceEligible, setIsTeenspaceEligible] = useState<boolean>(false);
  const [communityID, setCommunityID] = useState<string>();
  const clientTimezone = moment.tz.guess();

  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useQuerySubscriptions({
    clientUserID: userID,
    includePlanDetails: true,
    disabled,
  });
  const { data: clientInfo, isLoading: isLoadingClientInfo } = useQueryGetClient({
    clientUserID: userID,
    disabled,
  });

  const { data: communityUserData, isLoading: isLoadingCommunityUserData } =
    useQueryCommunityUserData({ clientUserID: userID, disabled });
  const { data: dailyPromptData, isLoading: isLoadingDailyPrompt } = useQueryDailyPrompt({
    clientUserID: userID,
    disabled,
  });

  const { dateOfBirth, state: clientState } = clientInfo || {};
  const clientAge = dateOfBirth && getAge({ dateOfBirth: dateOfBirth?.toISOString() });

  useEffect(() => {
    if (!disabled && subscriptions && hasTeenspaceCommunity(subscriptions)) {
      setIsTeenspaceEligible(true);
      setCommunityID(appConfig.amity.teenspaceCommunityID);
    }
  }, [subscriptions, dailyPromptData, disabled]);
  const displayName = communityUserData?.displayName;
  const canLoginToAmity = isTeenspaceEligible && displayName && clientState && dateOfBirth;

  const { accessToken, isConnected, isConnectionError } = useAmityLogin({
    disabled: !canLoginToAmity,
    userProfile: canLoginToAmity
      ? {
          displayName,
          state: clientState,
          dateOfBirth: dateOfBirth.toISOString(),
        }
      : undefined,
  });

  const dailyPromptTags = getCommunityFeedFilterTags({
    userID: String(userID),
    timezone: clientTimezone,
    shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
    feedType: CommunityFeedTypeEnum.TODAY,
  });
  const { data: replyCount } = useQueryCommunityFilteredPostCount({
    accessToken,
    communityID,
    tags: dailyPromptTags,
    disabled: !(isTeenspaceActive && subscriptions && hasTeenspaceCommunity(subscriptions)),
  });

  return (
    <CommunityContext.Provider
      value={{
        accessToken,
        communityID,
        userID: String(userID),
        displayName: communityUserData?.onboardedTeenspaceAt,
        clientTimezone,
        clientState,
        clientAge,
        isConnected,
        isConnectionError,
        isTeenspaceEligible,
        isTeenspaceOnboarded: Boolean(communityUserData?.onboardedTeenspaceAt),
        dailyPrompt: dailyPromptData,
        isLoadingDailyPrompt,
        replyCount,
        isLoading:
          !canLoginToAmity ||
          isLoadingSubscriptions ||
          isLoadingClientInfo ||
          isLoadingCommunityUserData ||
          isLoadingDailyPrompt,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export const useCommunityState = () => {
  const context = useContext(CommunityContext);
  if (context === undefined) {
    throw new Error('community context must be used within CommunityProvider');
  }
  return context;
};

export default CommunityProvider;
