import {
  Button,
  ChevronLeft,
  Divider,
  Grid,
  Row,
  Col,
  ResponsiveLayoutWithHeader,
  TextArea,
  TextDS,
  TouchableView,
  View,
  spacing,
  useWindowWidthState,
  StickyDrawer,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import UserTag from './UserTag';
import WeightedSentence from './WeightedSentence';

import { DailyPrompt } from '../types';
import useRotateOptionsArray from '../hooks/useRotateOptionsArray';
import { formatPlaceholder } from '../utils/helpers';
import { POST_MAX_LENGTH } from '../constants';

const { space100, space150, space200, space300, space600 } = spacing;

interface Props {
  communityUser: Amity.User | undefined;
  dailyPrompt: DailyPrompt;
  responseText: string;
  onChangeResponseText: (value: string) => void;
  onPressBack: () => void;
  onPressPost: () => void;
  isLoading: boolean;
}
const CommunityCreatePost = ({
  communityUser,
  dailyPrompt,
  responseText,
  onChangeResponseText,
  onPressBack,
  onPressPost,
  isLoading,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const { text: dailyPromptText, placeholders } = dailyPrompt || {};

  const placeholder = useRotateOptionsArray<string>({
    options: placeholders,
    intervalSeconds: 3,
  });
  const { colorRoles } = useEmotionTheme();
  const columnProps = { lg: 5 };
  const canPost = !isLoading && responseText.length > 0;
  return (
    <ResponsiveLayoutWithHeader
      headerHeight={58}
      renderHeader={() => (
        <View
          row
          align="center"
          justify="space-between"
          style={{ width: '100%', padding: `${space150}px ${space200}px` }}
        >
          <TouchableView onPress={onPressBack} dataQa="backButton">
            <ChevronLeft colorType="brand" />
          </TouchableView>
          {isMobile && (
            <Button
              variant="tertiary"
              sizeDS="slim"
              text="Post"
              dataQa="postButton"
              onPress={onPressPost}
              disabled={!canPost}
            />
          )}
        </View>
      )}
      contentWrapperStyle={{ padding: `${space600}px 0` }}
    >
      <Grid>
        <Row style={{ justifyContent: 'center' }}>
          <Col {...columnProps}>
            <View align="center">
              <WeightedSentence sentence={dailyPromptText} />
              <TextDS variant="bodySm" colorRole="textSubtlest" style={{ marginTop: space300 }}>
                Remember, your post will be anonymous
              </TextDS>
            </View>
            <Divider
              style={{ margin: `${space200}px 0` }}
              color={colorRoles.borders.borderSubtleDefault}
            />
            <UserTag user={communityUser} style={{ padding: `${space100}px 0` }} />
            <TextArea
              maxLength={POST_MAX_LENGTH}
              value={responseText}
              onChangeText={onChangeResponseText}
              style={{ width: '100%', marginTop: space150, borderWidth: 0 }}
              placeholder={formatPlaceholder(placeholder)}
            />
          </Col>
        </Row>
        {!isMobile && (
          <Row>
            <StickyDrawer noBorder>
              <Col {...columnProps} style={{ justifyContent: 'center' }}>
                <Button
                  text="Post"
                  dataQa="postButton"
                  onPress={onPressPost}
                  style={{ width: '100%', maxWidth: undefined }}
                  disabled={!canPost}
                />
              </Col>
            </StickyDrawer>
          </Row>
        )}
      </Grid>
    </ResponsiveLayoutWithHeader>
  );
};

export default CommunityCreatePost;
