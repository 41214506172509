import { useMemo } from 'react';
import * as React from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from 'react-router';

import {
  Grid,
  Col,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  Breadcrumb,
  TextDS,
  SpacingView,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { NAV_SHELL_HEADER_HEIGHT } from 'ts-frontend/constants';

import useOpenModalSafe from '@/hooks/useOpenModalSafe';

import { DeepLinkAction, useDeepLinkAction } from '../../hooks/useDeepLink';
import MobileHeader from '../../components/MobileLeftHeader';
import PersonalInformation from '../components/PersonalInformation';
import MyAccountPanelWrapper from '../components/MyAccountPanelWrapper/MyAccountPanelWrapper';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import { PersonalInformationContextProvider } from '../hooks/usePersonalInformation';

interface Props {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
}

const breadcrumbRoutes = [
  { label: 'Account', path: '/home/account' },
  { label: 'Personal Information', path: '' },
];

const PersonalInformationContainer: React.VFC<Props> = ({
  onCloseButtonPress,
  onBackButtonPress,
}) => {
  const isInFrame = ReactFrameService.instance().isInFrame();
  const isiOS = ReactFrameService.isiOS();
  const { isMobile } = useWindowWidthState();
  const openModal = useOpenModalSafe();
  const history = useHistory();
  const useNewNav = useNewMemberNav();
  const isHomeNav = useNewNav && history.location.pathname.includes('home');

  const handleRouting = (path) => {
    if (openModal) {
      openModal(path, undefined, isMobile, true);
    } else {
      history.push(path);
    }
  };

  const onEditMyInformation = () => {
    handleRouting('/edit-my-information');
  };

  const onEditEmergencyContact = () => {
    handleRouting('/edit-emergency-contact');
  };

  const onEditTeenspaceCommunity = () => {
    handleRouting('/edit-teenspace-community');
  };

  useDeepLinkAction(DeepLinkAction.PERSONAL_DETAILS)(
    useMemo(() => {
      return { openModal, isMobile, action: 'personal-details' };
    }, [openModal, isMobile])
  );

  const newNavHeaderHeight = isMobile ? 0 : NAV_SHELL_HEADER_HEIGHT;

  if (isHomeNav)
    return (
      <PersonalInformationContextProvider>
        <ResponsiveLayoutWithHeader
          outsideHeaderHeight={useNewNav ? newNavHeaderHeight : undefined}
          outsideFooterHeight={useNewNav ? 0 : undefined}
          headerHeight={!isMobile ? 0 : undefined}
          renderHeader={() => (
            <PanelHeader
              renderLeft={() => (
                <MobileHeader
                  centerTitle={isInFrame && isiOS}
                  title=""
                  onBackButtonPress={() => history.goBack()}
                />
              )}
              onRightPress={isInFrame ? undefined : onCloseButtonPress}
            />
          )}
          noHeaderLine
        >
          <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
            <Col sm={8} lg={8}>
              <SpacingView itemSpacing="space200" row={false}>
                {!isMobile && <Breadcrumb routes={breadcrumbRoutes} />}
                <TextDS variant="heading2xl">Personal Information</TextDS>

                <MyAccountPanelWrapper alwaysRow={isInFrame} title="">
                  <PersonalInformation
                    onEditMyInformation={onEditMyInformation}
                    onEditEmergencyContact={onEditEmergencyContact}
                    onEditTeenspaceCommunity={onEditTeenspaceCommunity}
                  />
                </MyAccountPanelWrapper>
              </SpacingView>
            </Col>
          </Grid>
        </ResponsiveLayoutWithHeader>
      </PersonalInformationContextProvider>
    );

  return (
    <PersonalInformationContextProvider>
      <ResponsiveLayoutWithHeader
        renderHeader={() => (
          <PanelHeader
            renderLeft={() => (
              <MobileHeader
                centerTitle={isInFrame && isiOS}
                title="My Account"
                mobileTitle="Personal information"
                onBackButtonPress={onBackButtonPress}
              />
            )}
            onRightPress={isInFrame ? undefined : onCloseButtonPress}
          />
        )}
      >
        <MyAccountPanelWrapper alwaysRow={isInFrame} title="Personal information">
          <PersonalInformation
            onEditMyInformation={onEditMyInformation}
            onEditEmergencyContact={onEditEmergencyContact}
            onEditTeenspaceCommunity={onEditTeenspaceCommunity}
          />
        </MyAccountPanelWrapper>
      </ResponsiveLayoutWithHeader>
    </PersonalInformationContextProvider>
  );
};

export default PersonalInformationContainer;
