import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18.3965C12.1217 18.3965 14.1566 17.5536 15.6569 16.0533C17.1571 14.553 18 12.5182 18 10.3965C18 8.27475 17.1571 6.23992 15.6569 4.73963C14.1566 3.23934 12.1217 2.39648 10 2.39648C7.87827 2.39648 5.84344 3.23934 4.34315 4.73963C2.84285 6.23992 2 8.27475 2 10.3965C2 12.5182 2.84285 14.553 4.34315 16.0533C5.84344 17.5536 7.87827 18.3965 10 18.3965ZM13.5312 8.92773L9.53125 12.9277C9.2375 13.2215 8.7625 13.2215 8.47188 12.9277L6.47188 10.9277C6.17813 10.634 6.17813 10.159 6.47188 9.86836C6.76562 9.57773 7.24062 9.57461 7.53125 9.86836L9 11.3371L12.4688 7.86523C12.7625 7.57148 13.2375 7.57148 13.5281 7.86523C13.8187 8.15898 13.8219 8.63398 13.5281 8.92461L13.5312 8.92773Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const CircleCheck = withDSIconMaker(pathConfig, 'circle check');

export default CircleCheck;
