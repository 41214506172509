import { RequireAtLeastOne } from '../types/generics';
import { BusinessDays } from '../types/Matches';

type DummyProviderData = RequireAtLeastOne<{
  therapistName?: string;
  licenseText?: string;
}>;

export const INACTIVE_PHASE_VALUES = [0, 4, 8];

export enum DummyProviderEnum {
  NO_MATCHES = 'NO_MATCHES',
  CANCELED_NO_MATCHES = 'CANCELED_NO_MATCHES',
  CT_REACTIVATION = 'CT_REACTIVATION',
}

export const DUMMY_PROVIDERS: Record<DummyProviderEnum, Required<DummyProviderData>> = {
  NO_MATCHES: {
    therapistName: 'Waiting to be matched',
    licenseText: 'Your match is pending...',
  },
  CANCELED_NO_MATCHES: {
    therapistName: 'Not yet matched',
    licenseText: '',
  },
  CT_REACTIVATION: {
    therapistName: 'Talkspace',
    licenseText: '',
  },
};

export type TherapistType = 'primary' | 'psychiatrist';
export type Availability = 'time-off' | 'available' | 'unavailable';

export interface UnavailabilityPeriod {
  startDate: string | null;
  endDate: string | null;
}
export interface TherapistInfo {
  id: number;
  therapistID?: number;
  title: string;
  firstName: string;
  lastName: string;
  licenses: string[];
  type: TherapistType;
  therapistType?: TherapistType;
  imageURL: string;
  publicDetails: string;
  psychologytodayProfileID: null | string;
  firstClinicalLicenseDate: string;
  businessHoursTimezone: string;
  availability: Availability;
  unavailabilityPeriod: UnavailabilityPeriod;
  businessDays: BusinessDays;
  expertise: Expertise;
  licenseDetails: LicenseDetail[];
  yearsInPractice: number;
  joinedYearsAgo: number;
  phase: number;
}

export interface Expertise {
  focus: string[];
  treatmentApproach: string[];
}

export interface LicenseDetail {
  state: string;
  number: string;
  name: string;
  description: string;
}

function toUpper(word: string): string {
  return word.replace(/^\w/, (c) => c.toUpperCase());
}
function convertToLegacyBusinessDays(obj?: {}): BusinessDays {
  if (!obj || !Object.keys(obj).length)
    return {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };
  return Object.entries(obj).reduce(
    (acc, [k, v]) => (k === 'availableNow' ? acc : { ...acc, [toUpper(k)]: v ? 1 : 0 }),
    {}
  );
}
export class ETherapistInfo implements TherapistInfo {
  id: number;

  title: string;

  firstName: string;

  lastName: string;

  licenses: string[];

  type: TherapistType;

  therapistType?: TherapistType;

  imageURL: string;

  publicDetails: string;

  psychologytodayProfileID: null | string;

  firstClinicalLicenseDate: string;

  businessHoursTimezone: string;

  availability: Availability;

  unavailabilityPeriod: UnavailabilityPeriod;

  businessDays: BusinessDays;

  expertise: Expertise;

  licenseDetails: LicenseDetail[];

  yearsInPractice: number;

  joinedYearsAgo: number;

  phase: number;

  constructor(therapist: TherapistInfo) {
    this.id = therapist.id;
    this.title = typeof therapist.title === 'string' ? therapist.title.trim() : '';
    this.firstName = therapist.firstName;
    this.lastName = therapist.lastName;
    this.licenses = therapist.licenses;
    this.type = therapist.type || therapist.therapistType;
    this.imageURL = therapist.imageURL;
    this.publicDetails = therapist.publicDetails;
    this.psychologytodayProfileID = therapist.psychologytodayProfileID;
    this.firstClinicalLicenseDate = therapist.firstClinicalLicenseDate;
    this.businessHoursTimezone = therapist.businessHoursTimezone;
    this.availability = therapist.availability;
    this.phase = therapist.phase;
    this.unavailabilityPeriod = {
      startDate: therapist.unavailabilityPeriod?.startDate
        ? therapist.unavailabilityPeriod.startDate
        : null,
      endDate: therapist.unavailabilityPeriod?.endDate
        ? therapist.unavailabilityPeriod.endDate
        : null,
    };
    // eslint-disable-next-line prefer-destructuring
    this.businessDays = convertToLegacyBusinessDays(therapist.businessDays);

    this.expertise = therapist.expertise;

    this.licenseDetails = therapist.licenseDetails;

    this.yearsInPractice = therapist.yearsInPractice;

    this.joinedYearsAgo = therapist.joinedYearsAgo;
  }
}

const isDummyProviderFactory =
  (providerType = DummyProviderEnum.NO_MATCHES) =>
  (providerData: DummyProviderData) =>
    providerData.therapistName === DUMMY_PROVIDERS[providerType].therapistName ||
    providerData.licenseText === DUMMY_PROVIDERS[providerType].licenseText;

export const isDummyNoMatchesProvider = isDummyProviderFactory(DummyProviderEnum.NO_MATCHES);
export const isDummyCanceledNoMatchesProvider = isDummyProviderFactory(
  DummyProviderEnum.CANCELED_NO_MATCHES
);

export const isDummyCTReactivationProvider = isDummyProviderFactory(
  DummyProviderEnum.CT_REACTIVATION
);

export const checkIfPsychiatrist = (therapistType: TherapistType): boolean =>
  therapistType === 'psychiatrist';
