import {
  Button,
  Col,
  Grid,
  ResponsiveLayoutWithHeader,
  Row,
  SimpleHeader,
  StickyDrawer,
  StickyDrawerParentContainer,
  TextDS,
  SpacingView,
  View,
  spacing,
  useWindowWidthState,
  BaseButton,
  getScreenSafeAreaInsets,
} from '@talkspace/react-toolkit';
import { Subscription } from 'ts-frontend/types';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { useHistory } from '@/core/routerLib';

import ScheduleChooseProviderListItem from './ScheduleChooseProviderListItem';
import ScheduleChooseProviderListLoadingSkeleton from './ScheduleChooseProviderListLoadingSkeleton';
import ScheduleChooseProviderNoBookableRooms from './ScheduleChooseProviderNoBookableRooms';
import ScheduleChooseProviderUpdatePayment from './ScheduleChooseProviderUpdatePayment';
import ScheduleChooseProviderWaitForMatch from './ScheduleChooseProviderWaitForMatch';
import { AggregatedRoomData } from '../../hooks/useNavShellData';
import { useIsModal } from '../../utils/ModalsContextProvider';

const { space300, space400, space700 } = spacing;

const ScheduleChooseProvider = ({
  dataByRoomID,
  subscriptions,
  onPressContinue,
  onPressUpdatePlan,
  onPressUpdatePayment,
  selectedRoomID,
  setSelectedRoomID,
  isLoading,
}: {
  dataByRoomID: AggregatedRoomData | undefined;
  subscriptions: Subscription[] | undefined;
  onPressContinue: () => void;
  onPressUpdatePlan: () => void;
  onPressUpdatePayment: (roomID: number) => void;
  selectedRoomID: number | undefined;
  setSelectedRoomID: (roomID: number) => void;
  isLoading: boolean;
}) => {
  const history = useHistory();
  const onClose = () => {
    history.goBack();
  };

  const { isDesktop } = useWindowWidthState();
  const isModal = useIsModal();
  // const columnParams = { sm: 6, smOffset: 1, lg: 4, lgOffset: 4 }; // TODO: offsets do not work. Grid bug?
  const columnParams = { sm: 6, lg: 4 };
  const bookableSubscriptions =
    subscriptions?.filter((subscription) => subscription.subscription.canCreateBooking) || [];
  const subscriptionPastDue = subscriptions?.find(
    (subscription) => subscription.status === RoomStatus.PAST_DUE
  );
  const showUpdatePlanLink = !isLoading && bookableSubscriptions.length !== subscriptions?.length;
  const subscriptionWaitingToBeMatched = subscriptions?.find(
    (subscription) =>
      subscription.status === RoomStatus.PENDING_SUBSCRIPTION ||
      subscription.status === RoomStatus.MBA
  );
  if (!isLoading && bookableSubscriptions.length === 0) {
    if (subscriptionWaitingToBeMatched) {
      return <ScheduleChooseProviderWaitForMatch onPressClose={onClose} />;
    }
    if (subscriptionPastDue) {
      const roomIDPastDue = subscriptionPastDue.id;
      return (
        <ScheduleChooseProviderUpdatePayment
          roomID={roomIDPastDue}
          onPressUpdatePayment={onPressUpdatePayment}
          onPressClose={onClose}
        />
      );
    }
    return (
      <ScheduleChooseProviderNoBookableRooms
        onPressUpdatePlan={onPressUpdatePlan}
        onPressClose={onClose}
      />
    );
  }
  const safeAreaInsets = getScreenSafeAreaInsets();

  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
      renderHeader={() => <SimpleHeader title="Book a session" onClose={onClose} />}
      contentWrapperStyle={{
        padding: isDesktop ? `${space700}px 0` : `${space400}px 0`,
      }}
    >
      <StickyDrawerParentContainer>
        <Grid>
          <Row>
            {/* // TODO: once offsets work we'll be able to remove this */}
            <Col sm={1} lg={4}>
              {null}
            </Col>
            <Col {...columnParams}>
              <TextDS variant="heading2xl" style={{ textAlign: 'center' }}>
                Which provider would you like to book with?
              </TextDS>
              <View style={{ marginTop: space300 }}>
                {isLoading ? (
                  <ScheduleChooseProviderListLoadingSkeleton />
                ) : (
                  <SpacingView itemSpacing="space150">
                    {bookableSubscriptions?.map((subscription) => (
                      <ScheduleChooseProviderListItem
                        roomData={dataByRoomID?.[subscription.id]}
                        subscription={subscription}
                        onPress={() => {
                          setSelectedRoomID(subscription.id);
                        }}
                        selectedRoomID={selectedRoomID}
                      />
                    ))}
                  </SpacingView>
                )}
              </View>
              {showUpdatePlanLink && (
                <TextDS
                  variant="bodyXs"
                  colorRole="textSubtlest"
                  style={{ textAlign: 'center', marginTop: space300 }}
                >
                  Don’t see a provider you’re looking for? This means either you canceled your plan,
                  used all your credits, or your coverage has expired.{' '}
                  <BaseButton
                    dataQa="continueButton"
                    disabled={isLoading}
                    onPress={onPressUpdatePlan}
                    style={{ padding: 0, display: 'inline' }}
                  >
                    <TextDS
                      variant="bodyXs"
                      colorRole="textBrandDefault"
                      style={{ textDecoration: 'underline' }}
                    >
                      Update your plan
                    </TextDS>
                  </BaseButton>
                </TextDS>
              )}
            </Col>
          </Row>
          <StickyDrawer
            contentWrapperStyle={{ padding: `16px 20px ${24 + safeAreaInsets.bottom}px` }}
          >
            <Grid>
              <Row>
                {/* // TODO: once offsets work we'll be able to remove this */}
                <Col sm={1} lg={4}>
                  {null}
                </Col>
                <Col {...columnParams}>
                  <Button
                    dataQa="continueButton"
                    disabled={isLoading}
                    onPress={onPressContinue}
                    text="Continue"
                    sizeDS="large"
                    style={{ margin: 0, width: '100%', maxWidth: undefined }}
                  />
                </Col>
              </Row>
            </Grid>
          </StickyDrawer>
        </Grid>
      </StickyDrawerParentContainer>
    </ResponsiveLayoutWithHeader>
  );
};

export default ScheduleChooseProvider;
